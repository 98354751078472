import React, { useMemo } from 'react'
import get from 'lodash.get'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { createId } from '@utils/common.utils'
import { Box, Typography } from '@mui/material'
import { DEFAULT_TOOLTIP_DATE_FORMAT, formatTimestamp, ISO_CALENDAR_WEEK_FORMAT } from '@utils/moment.utils'
import { PROMOTION_DAYS } from '@constants/promotions.constants'
import { getPromotionDetailsAtDatapoint } from '@utils/promotions.utils'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY } from '@constants/insights.constants'

import {
  defaultInsightsTooltipValueFormatter,
  generateAbsDeviationPayloadKey,
  generateLastYearPayloadKey,
  generatePredictionPayloadKey,
  getInsightsLineColor,
} from '@utils/insights.utils'

import ChartTooltipLegendItemComponent from '@components/charts/ChartTooltipLegendItem'
import ChartTooltipContainerComponent from '@components/charts/ChartTooltipContainer'

export interface TooltipRowsItem {
  /**
   * Label to be displayed
   */
  label: string,
  /**
   * Key to be used to get the value from the data
   */
  targetKey: string,
  /**
   * Key to be used to get the prediction value from the data
   */
  predictionKey: string,
  /**
   * Key to be used to get the absolute deviation value from the data
   */
  absDeviationKey: string,
  /**
   * Key to be used to get the last year value from the data
   */
  lastYearKey: string,
  /**
   * Color to be used for the row actual value
   */
  secondaryColor: string,
  /**
   * Color to be used for the row predicted value
   */
  primaryColor: string,
  /**
   * Last year color to be used for the row last year value
   */
  lastYearColor: string,
  /**
   * Default color to be used for the row absolute deviation value
   */
  defaultColor: string,
}

export interface InsightsChartRowComponentProps {
  /**
   * Label to be displayed
   */
  label: string
  /**
   * Key to be used to get the color from the data
   */
  colorKey: 'primaryColor' | 'secondaryColor' | 'defaultColor' | 'lastYearColor'
  /**
   * Key to be used to get the value from the data
   */
  valueKey: keyof TooltipRowsItem
  /**
   * Rows to be displayed
   */
  rows: TooltipRowsItem[]
  /**
   * Data to be displayed
   */
  data: Insights.BaseChartDatasetItem
  /**
   * Flag to indicate if the intervals should be shown
   */
  showIntervals?: boolean
  /**
   * Value to be displayed instead of the value from the data
   */
  valueOverride?: number | null
}

export const InsightsChartRowComponent: React.FC<InsightsChartRowComponentProps> = ({
  label,
  valueKey,
  colorKey,
  rows,
  data,
  showIntervals = true,
  valueOverride,
}) => {
  const intl = useIntl()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      data-testid={InsightsChartRowComponent.name}
    >
      <Typography
        fontWeight={500}
        textAlign='right'
        variant='body1'
      >
        {label}
      </Typography>

      {
        rows.map((row, index) => {
          const value: number | null = valueOverride !== undefined ? valueOverride : get(data, row[valueKey], null)
          const valueToRender = defaultInsightsTooltipValueFormatter(intl, value, { showIntervals })

          return (
            <Typography
              key={createId(index, valueKey)}
              variant='body1'
              textAlign='right'
              color={row[colorKey]}
            >
              {valueToRender}
            </Typography>
          )
        })
      }
    </Box>
  )
}

export interface InsightsChartTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * Flag to indicate if the tooltip is active
   */
  active?: boolean
  /**
   * Flag to indicate if the chart is fetching data
   */
  isFetching?: boolean
  /**
   * Flag to indicate if the promotion days should be shown
   */
  promotionsVisibility?: boolean
  /**
   * Flag to indicate if the last year value should be shown
   */
  lastYearVisibility?: boolean
  /**
   * Prefix for the last year target key
   */
  lastYearTargetPayloadKeyPrefix?: string
  /**
   * Prefix for the prediction key
   */
  predictionKeyPrefix?: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationKeyPrefix?: string
  /**
   * Selected rows in the grid
   */
  selectedRows?: GridRowSelectionModel
  /**
   * Data to be displayed in the tooltip
   */
  payload?: Insights.BaseChartDatasetItem[]
  /**
   * Lines to be displayed in the chart
   */
  lines: Insights.BaseChartLineItem[]
  /**
   * Flag to show promotion days
   */
  promotionsToExclude?: PROMOTION_DAYS[]
}

const InsightsChartGroupingTooltipComponent: React.FC<InsightsChartTooltipProps> = ({
  active, payload, lines, isFetching,
  selectedRows = [],
  promotionsToExclude = [],
  promotionsVisibility = true,
  lastYearVisibility = true,
  predictionKeyPrefix = '',
  absDeviationKeyPrefix = '',
  lastYearTargetPayloadKeyPrefix = '',
}) => {
  const intl = useIntl()
  const data: Insights.BaseChartDatasetItem = get(payload, '[0].payload', {})
  const {
    formattedEvents,
    hasEvents,
    label: promotionLabel,
  } = getPromotionDetailsAtDatapoint({
    data,
    promotionsVisibility,
    promotionsToExclude,
    intl,
  })

  const sortedRows = useMemo(() => {
    const rows: TooltipRowsItem[] = []

    if (!active || !payload || !payload.length || !lines || !lines.length) {
      return rows
    }

    lines.forEach((line, index) => {
      rows.push({
        label: line.label,
        targetKey: line.id,
        predictionKey: generatePredictionPayloadKey(line.id, predictionKeyPrefix),
        absDeviationKey: generateAbsDeviationPayloadKey(line.id, absDeviationKeyPrefix),
        lastYearKey: generateLastYearPayloadKey(line.id, lastYearTargetPayloadKeyPrefix),
        defaultColor: 'black',
        secondaryColor: getInsightsLineColor(index, line.id, {
          isFetching,
          hasGroupingEnabled: true,
          useColorWay: true,
          selectedRows,
        }),
        primaryColor: getInsightsLineColor(index, line.id, {
          isFetching,
          hasGroupingEnabled: true,
          useColorWay: true,
          selectedRows,
        }),
        lastYearColor: getInsightsLineColor(index, line.id, {
          isFetching,
          hasGroupingEnabled: true,
          useColorWay: true,
          colorWayOpacity: INSIGHTS_GROUPING_LAST_YEAR_COLORWAY_OPACITY,
          selectedRows,
        }),
      })
    })

    return rows.sort((a, b) => {
      const aPredictionValue: number = get(data, a.predictionKey, 0) as number
      const bPredictionValue: number = get(data, b.predictionKey, 0) as number
      const aTruthValue: number = get(data, a.targetKey, 0) as number
      const bTruthValue: number = get(data, b.targetKey, 0) as number
      const aName = a.label
      const bName = b.label

      if (bPredictionValue !== aPredictionValue) {
        return bPredictionValue - aPredictionValue
      }

      if (bTruthValue !== aTruthValue) {
        return bTruthValue - aTruthValue
      }

      return aName.localeCompare(bName)
    })
  }, [
    active, payload, lines, isFetching, selectedRows, data,
    predictionKeyPrefix, absDeviationKeyPrefix, lastYearTargetPayloadKeyPrefix,
  ])

  if (active && !isFetching && payload && sortedRows && sortedRows.length && payload.length) {
    return (
      <ChartTooltipContainerComponent
        data-testid={InsightsChartGroupingTooltipComponent.name}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'insights.chart.x.title' })}
          </Typography>
          <Typography>
            {formatTimestamp(data.date, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT)}
          </Typography>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          mt={0.5}
          mb={hasEvents ? 0 : 2}
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'insights.chart.x.calendarWeek' })}
          </Typography>
          <Typography>
            {formatTimestamp(data.date, intl.locale, ISO_CALENDAR_WEEK_FORMAT)}
          </Typography>
        </Box>

        {
          hasEvents ? (
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              mt={0.5}
              mb={2}
            >
              <Typography
                fontWeight={500}
                pr={1}
              >
                {promotionLabel}
              </Typography>
              <Typography>
                {formattedEvents}
              </Typography>
            </Box>
          ) : (
            null
          )
        }

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='flex-start'
          gap={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Typography>
              &nbsp;
            </Typography>

            {
              sortedRows.map((row, index) => {
                return (
                  <ChartTooltipLegendItemComponent
                    type='line'
                    color={row.primaryColor}
                    label={row.label}
                    key={createId(index, row.label)}
                  />
                )
              })
            }
          </Box>

          {
            lastYearVisibility ? (
              <InsightsChartRowComponent
                label={intl.formatMessage({ id: 'insights.chart.tooltip.lastYearResults' })}
                colorKey='lastYearColor'
                valueKey='lastYearKey'
                rows={sortedRows}
                data={data}
              />
            ) : (
              null
            )
          }

          <InsightsChartRowComponent
            label={intl.formatMessage({ id: 'insights.chart.tooltip.actual' })}
            colorKey='secondaryColor'
            valueKey='targetKey'
            rows={sortedRows}
            data={data}
            valueOverride={data.virtualTarget ? null : undefined}
          />

          <InsightsChartRowComponent
            label={intl.formatMessage({ id: 'insights.chart.tooltip.predicted' })}
            colorKey='primaryColor'
            valueKey='predictionKey'
            rows={sortedRows}
            data={data}
          />

          {
            absDeviationKeyPrefix ? (
              <InsightsChartRowComponent
                label={intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' })}
                colorKey='defaultColor'
                valueKey='absDeviationKey'
                rows={sortedRows}
                data={data}
                showIntervals={false}
              />
            ) : (
              null
            )
          }
        </Box>
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default InsightsChartGroupingTooltipComponent
