import {
  takeLatest,
  put, call,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { PROMOTION_DAYS } from '@constants/promotions.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload } from '@redux/modules/types'
import { setPromotionsVisibility, setPromotionsToExclude } from '@utils/local-storage.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'

import * as API from '@redux/modules/hera/hera.api'

import {
  REQUEST_PROMOTIONS_SETTINGS_CHANGE,
  REQUEST_PROMOTIONS_VISIBILITY_CHANGE,
  REQUEST_INSIGHTS_ABC_TOTALS,
  REQUEST_INSIGHTS_PIPELINE_RUN_INFO,
  REQUEST_LAST_YEAR_VISIBILITY_CHANGE,
} from './insights.action-types'

import {
  receivePromotionsSettingsChangeAction,
  receivePromotionsVisibilityChangeAction,
  receiveInsightsPipelineRunInfoAction,
  receiveLastYearVisibilityChangeAction,
  startFetchingInsightsAction,
  stopFetchingInsightsAction,
  receiveInsightsAbcTotalsAction,
} from './insights.actions'

export function* requestInsightsAbcTotalsGenerator({ payload }: ActionPayload<Hera.AbcTotalsApiRequest>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_INSIGHTS_ABC_TOTALS))

    const response: Hera.AbcTotalsApiResponse = yield call(API.getABCTotals, payload)

    yield put(receiveInsightsAbcTotalsAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveInsightsAbcTotalsAction({}))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_INSIGHTS_ABC_TOTALS))
  }
}

export function* requestInsightsPipelineRunInfoHandler({ payload }: ActionPayload<Hera.LatestPipelineRunInfoAPIRequest>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_INSIGHTS_PIPELINE_RUN_INFO))

    const response: Hera.LatestPipelineRunInfoAPIResponse = yield call(API.getInsightsLatestPipelineRunInfo, payload)

    yield put(receiveInsightsPipelineRunInfoAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveInsightsPipelineRunInfoAction({}))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_INSIGHTS_PIPELINE_RUN_INFO))
  }
}

export function* requestPromotionsVisibilityChangeGenerator({ payload }: ActionPayload<boolean>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_PROMOTIONS_VISIBILITY_CHANGE))

    yield call(setPromotionsVisibility, payload)

    yield put(receivePromotionsVisibilityChangeAction(payload))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_PROMOTIONS_VISIBILITY_CHANGE))
  }
}

export function* requestPromotionsSettingsChangeGenerator({ payload }: ActionPayload<PROMOTION_DAYS[]>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_PROMOTIONS_SETTINGS_CHANGE))

    yield call(setPromotionsToExclude, payload)

    yield put(receivePromotionsSettingsChangeAction(payload))

    yield put(setPrimaryModalPageName(null))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_PROMOTIONS_SETTINGS_CHANGE))
  }
}

export function* requestLastYearVisibilityChangeGenerator({ payload }: ActionPayload<boolean>) {
  try {
    yield put(startFetchingInsightsAction(REQUEST_LAST_YEAR_VISIBILITY_CHANGE))

    yield put(receiveLastYearVisibilityChangeAction(payload))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingInsightsAction(REQUEST_LAST_YEAR_VISIBILITY_CHANGE))
  }
}

export function* watchRequestInsightsAbcTotals() {
  yield takeLatest(REQUEST_INSIGHTS_ABC_TOTALS, requestInsightsAbcTotalsGenerator)
}

export function* watchRequestInsightsPipelineRunInfo() {
  yield takeLatest(REQUEST_INSIGHTS_PIPELINE_RUN_INFO, requestInsightsPipelineRunInfoHandler)
}

export function* watchRequestPromotionsVisibilityChange() {
  yield takeLatest(REQUEST_PROMOTIONS_VISIBILITY_CHANGE, requestPromotionsVisibilityChangeGenerator)
}

export function* watchRequestPromotionsSettingsChange() {
  yield takeLatest(REQUEST_PROMOTIONS_SETTINGS_CHANGE, requestPromotionsSettingsChangeGenerator)
}

export function* watchRequestLastYearVisibilityChange() {
  yield takeLatest(REQUEST_LAST_YEAR_VISIBILITY_CHANGE, requestLastYearVisibilityChangeGenerator)
}

export default {
  watchRequestInsightsAbcTotals,
  watchRequestInsightsPipelineRunInfo,
  watchRequestPromotionsVisibilityChange,
  watchRequestPromotionsSettingsChange,
  watchRequestLastYearVisibilityChange,
}
