import { State } from '@redux/modules/types'

import {
  RECEIVE_INSIGHTS_ABC_TOTALS,
  REQUEST_INSIGHTS_PIPELINE_RUN_INFO,
  REQUEST_PROMOTIONS_SETTINGS_CHANGE,
  REQUEST_PROMOTIONS_VISIBILITY_CHANGE,
  REQUEST_LAST_YEAR_VISIBILITY_CHANGE,
} from './insights.action-types'

export const getInsightsPipelineRunInfo = (state: State) => {
  return state.insights.pipelineRunInfo
}

export const getInsightsAbcTotals = (state: State) => {
  return state.insights.abcTotals
}

export const isFetchingPipelineRunInfo = (state: State) => {
  return state.insights.fetchingKeys.includes(REQUEST_INSIGHTS_PIPELINE_RUN_INFO)
}

export const isFetchingAbcTotals = (state: State) => {
  return state.insights.fetchingKeys.includes(RECEIVE_INSIGHTS_ABC_TOTALS)
}

export const getPromotionsVisibility = (state: State) => {
  return state.insights.promotionsVisibility
}

export const getLastYearVisibility = (state: State) => {
  return state.insights.lastYearVisibility
}

export const getPromotionsToExclude = (state: State) => {
  return state.insights.promotionsToExclude
}

export const isSubmittingPromotionSettings = (state: State) => {
  return state.insights.fetchingKeys.includes(REQUEST_PROMOTIONS_SETTINGS_CHANGE) ||
         state.insights.fetchingKeys.includes(REQUEST_PROMOTIONS_VISIBILITY_CHANGE) ||
         state.insights.fetchingKeys.includes(REQUEST_LAST_YEAR_VISIBILITY_CHANGE)
}
