import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
  SidePanelButtonContainerComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import DeleteIcon from '@icons/delete.icon'
import ButtonComponent from '@base/buttons/Button'
import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import SwitchFieldComponent from '@base/forms/SwitchField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CONNECT_EVALUATION_PROFILE_MODAL_NAME } from '@constants/modals.constants'
import { getUseCaseAvailableMetaDataColumns, isFetchingUseCaseMetaDataColumns } from '@redux/modules/use-case/use-case.selectors'
import { TIME_RESOLUTION, TIME_RESOLUTION_OPTIONS } from '@constants/date.constants'
import { requestUseCaseAvailableMetaDataColumnsAction } from '@redux/modules/use-case/use-case.actions'
import { isSubmittingEvaluationProfile } from '@redux/modules/parameters/parameters.selectors'
import { createEvaluationProfileAction, removeEvaluationProfileAction, updateEvaluationProfileAction } from '@redux/modules/parameters/parameters.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'

import connectParameterValidations from './ConnectEvaluationProfileModal.validations'

export interface ConnectEvaluationProfileModalDetails {
  returnTo: string
  parameterItem: UseCase.EvaluationProfile
}

const ConnectEvaluationProfileModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isSubmitting = useSelector((state) => isSubmittingEvaluationProfile(state))
  const isFetchingUseCaseAvailableColumns = useSelector((state) => isFetchingUseCaseMetaDataColumns(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const useCaseAvailableColumns = useSelector((state) => getUseCaseAvailableMetaDataColumns(state))
  const modalDetails = useSelector((state) => getModalDetails<ConnectEvaluationProfileModalDetails>(state))

  const open = modalPageName === CONNECT_EVALUATION_PROFILE_MODAL_NAME
  const edit = Boolean(modalDetails.parameterItem)

  const initialValues = useMemo(() => {
    if (edit) {
      return {
        ...modalDetails.parameterItem,
      }
    }

    return {
      name: '',
      timeCutMin: 1,
      timeCutMax: 3,
      timeResolution: TIME_RESOLUTION.DAILY,
      timeCompensating: true,
      hierarchyCompensating: true,
      hierarchyLevel: '',
      decisionMaker: '',
    } as UseCase.EvaluationProfile
  }, [
    modalDetails,
    edit,
  ])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: UseCase.EvaluationProfile) => {
    const payload = {
      ...values,
      useCaseId: usecase,
      timeCutMin: Number(values.timeCutMin),
      timeCutMax: Number(values.timeCutMax),
    }

    if (edit) {
      dispatch(
        updateEvaluationProfileAction(payload),
      )
    } else {
      dispatch(
        createEvaluationProfileAction(payload),
      )
    }
  }

  const handleDelete = () => {
    dispatch(
      removeEvaluationProfileAction({
        useCaseId: usecase,
        evaluationProfileId: modalDetails.parameterItem.evaluationProfileId!,
      }),
    )
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    isValid,
    values,
    errors,
    touched,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: connectParameterValidations(intl),
  })

  useEffect(() => {
    if (open && isAdmin) {
      dispatch(
        requestUseCaseAvailableMetaDataColumnsAction({ useCaseId: usecase }),
      )
    } else {
      resetForm()
    }
  }, [
    dispatch,
    resetForm,
    usecase,
    isAdmin,
    open,
  ])

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: edit ? 'connect.modal.evaluationProfile.edit' : 'connect.modal.evaluationProfile.create' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetchingUseCaseAvailableColumns}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer divider={edit} spacing={2}>
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.name' })}
                      floatingHelp={intl.formatMessage({ id: 'connect.modal.evaluationProfile.name.help' })}
                      name='name'
                      value={values.name}
                      touched={touched.name}
                      errors={errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.decisionMaker' })}
                      floatingHelp={intl.formatMessage({ id: 'connect.modal.evaluationProfile.decisionMaker.help' })}
                      name='decisionMaker'
                      value={values.decisionMaker}
                      touched={touched.decisionMaker}
                      errors={errors.decisionMaker}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  container={true}
                  hidden={!isAdmin}
                >
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='timeResolution'
                      value={values.timeResolution}
                      useIntlForLabel={true}
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.timeResolution' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      labelKey='labelKey'
                      options={TIME_RESOLUTION_OPTIONS}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='timeCompensating'
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.timeCompensating' })}
                      checked={values.timeCompensating}
                      value={values.timeCompensating}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  container={true}
                  hidden={!isAdmin}
                >
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.minTimeCut' })}
                      floatingHelp={intl.formatMessage({ id: 'connect.modal.evaluationProfile.minTimeCut.help' })}
                      name='timeCutMin'
                      value={values.timeCutMin}
                      touched={touched.timeCutMin}
                      errors={errors.timeCutMin}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      type='number'
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.maxTimeCut' })}
                      floatingHelp={intl.formatMessage({ id: 'connect.modal.evaluationProfile.maxTimeCut.help' })}
                      name='timeCutMax'
                      value={values.timeCutMax}
                      touched={touched.timeCutMax}
                      errors={errors.timeCutMax}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  container={true}
                  hidden={!isAdmin}
                >
                  <FormLayoutItem xs={6}>
                    <AutocompleteChipsFieldComponent<string>
                      freeSolo={true}
                      name='hierarchyLevel'
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.hierarchyLevel' })}
                      floatingHelp={intl.formatMessage({ id: 'connect.modal.evaluationProfile.hierarchyLevel.help' })}
                      placeholder={intl.formatMessage({ id: 'connect.modal.evaluationProfile.hierarchyLevel.placeholder' })}
                      value={values.hierarchyLevel ? [values.hierarchyLevel] : []}
                      errors={[errors.hierarchyLevel] as string[]}
                      options={useCaseAvailableColumns}
                      getOptionLabel={(item: string) => item}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                        handleChange({
                          target: {
                            name: 'hierarchyLevel',
                            value: selectedValue,
                          },
                        })
                      }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='hierarchyCompensating'
                      label={intl.formatMessage({ id: 'connect.modal.evaluationProfile.hierarchyCompensating' })}
                      checked={values.hierarchyCompensating}
                      value={values.hierarchyCompensating}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>

            <SidePanelButtonContainerComponent
              hidden={!edit || !isAdmin}
            >
              <ButtonComponent
                color='tertiary'
                name='deleteButton'
                StartIconComponent={DeleteIcon}
                label={intl.formatMessage({ id: 'connect.modal.parameter.delete' })}
                onClick={handleDelete}
              />
            </SidePanelButtonContainerComponent>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='connectEvaluationProfileModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='connectEvaluationProfileModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid || !dirty}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ConnectEvaluationProfileModalContainer
