import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  Menu, ListItemIcon, Typography,
  Box, SxProps, Theme, useTheme,
  MenuItem,
} from '@mui/material'

import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { createId } from '@utils/common.utils'
import { getButtonsStyles } from '@utils/ui.utils'

import ExportIcon from '@icons/export.icon'
import IconButtonComponent, { IconButtonComponentProps } from '@base/buttons/IconButton/IconButton.component'
import SwitchBaseComponent from '@base/forms/SwitchBase'
import TooltipComponent from '@base/tooltips/Tooltip'

export interface ContextMenuAction {
  name: string,
  icon?: React.ReactNode,
  type?: 'button' | 'toggle',
  label: string,
  checked?: boolean,
  disabled?: boolean,
  disabledReason?: string,
  handler?: () => void,
}

export interface ContextMenuProps {
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>,
  /**
   * Label for the button, shown as tooltip
   */
  label: string,
  /**
   * Name of the component, used for testing & tracking purposes
   */
  name: string,
  /**
   * Show label in a tooltip on hover
   * @default true
   */
  tooltip?: boolean,
  /**
   * Icon component to be rendered
   */
  IconComponent?: React.FC<Common.IconProps>,
  /**
   * Icon component props
   */
  iconComponentProps?: Common.IconProps,
  /**
   * Actions to be shown in the context menu
   */
  actions?: ContextMenuAction[],
  /**
   * Main button color
   * @default 'secondary'
   */
  buttonColor?: IconButtonComponentProps['color'],
}

const ContextMenuComponent: React.FC<ContextMenuProps> = ({
  actions = [],
  tooltip = true,
  label,
  name,
  sx,
  IconComponent = ExportIcon,
  iconComponentProps,
  buttonColor = 'secondary',
}) => {
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()
  const [actionMenu, setActionMenu] = useState<Element | null>(null)
  const isActive = Boolean(actionMenu)

  const handleActionIconClick = (e: React.SyntheticEvent) => {
    setActionMenu(e.currentTarget)
  }

  const handleActionClick = (e: React.SyntheticEvent, actionItem: ContextMenuAction) => {
    e.preventDefault()

    if (actionItem.type !== 'toggle') {
      setActionMenu(null)
    }

    trackEvent({
      componentName: actionItem.name,
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      router: params,
    })

    if (actionItem.handler) {
      actionItem.handler()
    }
  }

  const buttonStyles = getButtonsStyles(theme, 'secondary', 'icon', false)
  const buttonToRender = (
    <IconButtonComponent
      tooltip={tooltip}
      name={name}
      IconComponent={IconComponent}
      iconComponentProps={iconComponentProps}
      onClick={(e) => handleActionIconClick(e)}
      label={label}
      color={buttonColor}
      sx={isActive ? buttonStyles['&:focus-visible'] : {}}
    />
  )

  return (
    <Box
      data-testid={ContextMenuComponent.name}
    >
      {buttonToRender}

      <Menu
        anchorEl={actionMenu}
        open={isActive}
        onClose={() => setActionMenu(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            transform: 'translate(0px, 5px) !important',
          },
        }}
      >
        {
          actions?.map((actionItem, actionIndex) => {
            const menuItemSx = actionItem.type === 'toggle' ? {
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              maxHeight: '36px',
              '&.Mui-disabled': {
                pointerEvents: 'auto',
              },
            } : {
              '&.Mui-disabled': {
                pointerEvents: 'auto',
              },
            }

            const menuItem = (
              <MenuItem
                key={createId(actionIndex)}
                data-testid={actionItem.name}
                disabled={actionItem.disabled}
                onClick={(e) => {
                  if (actionItem.disabled) {
                    return
                  }

                  handleActionClick(e, actionItem)
                }}
                sx={menuItemSx}
              >
                {
                  actionItem.icon ? (
                    <ListItemIcon
                      sx={{
                        minWidth: `${theme.spacing(4)} !important`,
                      }}
                    >
                      {actionItem.icon}
                    </ListItemIcon>
                  ) : (
                    null
                  )
                }

                <Typography
                  noWrap={true}
                  variant='body1'
                >
                  {actionItem.label}
                </Typography>

                {
                  actionItem.type === 'toggle' ? (
                    <SwitchBaseComponent
                      name={actionItem.name}
                      checked={actionItem.checked}
                      disabled={actionItem.disabled}
                      onChange={(e) => handleActionClick(e, actionItem)}
                    />
                  ) : (
                    null
                  )
                }
              </MenuItem>
            )

            return actionItem.disabled && actionItem.disabledReason ? (
              <TooltipComponent
                key={createId(actionIndex)}
                title={actionItem.disabledReason}
                placement='left'
                tooltipSx={{ zIndex: 9999 }}
              >
                {menuItem}
              </TooltipComponent>
            ) : (
              menuItem
            )
          })
        }
      </Menu>
    </Box>
  )
}

export default ContextMenuComponent
