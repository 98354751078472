import React from 'react'
import { Box } from '@mui/material'
import { GridColumnHeaderParams, gridClasses } from '@mui/x-data-grid-premium'

import InfoButtonIcon from '@icons/infoButton.icon'

import TooltipComponent from '@base/tooltips/Tooltip/Tooltip.component'

export interface DataGridCustomColHeaderComponentProps {
  params: GridColumnHeaderParams
  subtitle?: string
  tooltip?: string | React.ReactNode
  align?: 'left' | 'center' | 'right'
}

export const DataGridCustomColHeaderComponent: React.FC<DataGridCustomColHeaderComponentProps> = ({
  params,
  subtitle,
  tooltip,
  align = 'left',
}) => {
  if (!params.colDef.headerName) {
    return null
  }

  const alignStyles = align === 'center' ? 'center' : align === 'right' ? 'flex-end' : 'flex-start'
  const containerStyles = subtitle ? {
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignStyles,
  } : {}

  const subtitleStyles = tooltip ? {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  } : {}

  const title = (
    <Box
      className={gridClasses.columnHeaderTitle}
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'var(--unstable_DataGrid-headWeight)',
      }}
    >
      {params.colDef.headerName}
    </Box>
  )

  const content = (
    <Box
      sx={containerStyles}
    >
      {title}

      {
        subtitle ? (
          <Box
            className={gridClasses.aggregationColumnHeaderLabel}
            lineHeight='0.75rem'
            fontWeight='500'
            sx={subtitleStyles}
          >
            {
              tooltip ? (
                <InfoButtonIcon
                  detailsFill='rgba(0, 0, 0, 0.4)'
                  width={12}
                  height={12}
                />
              ) : (
                null
              )
            }

            {subtitle}
          </Box>
        ) : (
          null
        )
      }
    </Box>
  )

  return tooltip ? (
    <TooltipComponent
      title={tooltip}
      placement='bottom'
    >
      {content}
    </TooltipComponent>
  ) : (
    content
  )
}

export default DataGridCustomColHeaderComponent
