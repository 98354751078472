import moment from 'moment'
import { IntlShape } from 'react-intl'
import {
  PROMOTION_DATES_TO_LABELS_MAP, PROMOTION_DAYS,
  PROMOTION_DAYS_LIST, PromotionItem,
} from '@constants/promotions.constants'

/* eslint-disable newline-per-chained-call */

/**
 * Get Thanksgiving date for a given year
 * @param year Year
 * @returns Thanksgiving date
 */
export const getThanksgiving = (year: number) => {
  // Start on Nov 1st
  const thanksgiving = moment().year(year).month(10).startOf('month')
  // Day of the week for November 1st
  const dayOfWeek = thanksgiving.day()
  // Calculate how many days to add to get to the first Thursday
  // If November 1 is after Thursday, add the necessary number of days
  const daysToThursday = (4 - dayOfWeek + 7) % 7
  // Now it's the first Thursday of November
  thanksgiving.add(daysToThursday, 'days')
  // Add 3 more weeks to get the fourth Thursday
  thanksgiving.add(21, 'days')

  return thanksgiving
}

export const PROMOTION_DATES: {
  [key in PROMOTION_DAYS]: (year: number) => PromotionItem[]
} = {
  // Static dates
  [PROMOTION_DAYS.VALENTINES_DAY]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.VALENTINES_DAY],
    from: moment(`${year}-02-14`).startOf('day'),
    to: moment(`${year}-02-14`).endOf('day'),
  }]),
  [PROMOTION_DAYS.WOMENS_DAY]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.WOMENS_DAY],
    from: moment(`${year}-03-08`).startOf('day'),
    to: moment(`${year}-03-08`).endOf('day'),
  }]),
  [PROMOTION_DAYS.EARTH_DAY]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.EARTH_DAY],
    from: moment(`${year}-04-22`).startOf('day'),
    to: moment(`${year}-04-22`).endOf('day'),
  }]),
  [PROMOTION_DAYS.HALLOWEEN]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.HALLOWEEN],
    from: moment(`${year}-10-31`).startOf('day'),
    to: moment(`${year}-10-31`).endOf('day'),
  }]),
  [PROMOTION_DAYS.SINGLES_DAY]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.SINGLES_DAY],
    from: moment(`${year}-11-11`).startOf('day'),
    to: moment(`${year}-11-11`).endOf('day'),
  }]),
  [PROMOTION_DAYS.NIKOLAUS]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.NIKOLAUS],
    from: moment(`${year}-12-06`).startOf('day'),
    to: moment(`${year}-12-06`).endOf('day'),
  }]),
  [PROMOTION_DAYS.CHRISTMAS]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.CHRISTMAS],
    from: moment(`${year}-12-24`).startOf('day'),
    to: moment(`${year}-12-26`).endOf('day'),
  }]),

  // Dynamic dates
  [PROMOTION_DAYS.EASTER]: (year) => {
    const easterDate = getEasterDateByYear(year)
    const easterStartDate = moment(`${year}-${easterDate.month}-${easterDate.day}`)
    const labelKey = PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.EASTER]

    return [{
      labelKey,
      from: easterStartDate.clone().subtract(2, 'day').startOf('day'),
      to: easterStartDate.clone().add(1, 'day').endOf('day'),
    }]
  },

  [PROMOTION_DAYS.MOTHERS_DAY]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.MOTHERS_DAY],
    from: moment().year(year).month(4).day('Sunday').add(1, 'week').startOf('day'),
    to: moment().year(year).month(4).day('Sunday').add(1, 'week').endOf('day'),
  }]),
  [PROMOTION_DAYS.FATHERS_DAY]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.FATHERS_DAY],
    from: moment().year(year).month(5).day('Sunday').startOf('day'),
    to: moment().year(year).month(5).day('Sunday').endOf('day'),
  }]),
  [PROMOTION_DAYS.PRIME_DEAL_DAYS]: (year) => {
    if (year === 2022) {
      return [{
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-07-12`).startOf('day'),
        to: moment(`${year}-07-13`).endOf('day'),
      }, {
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-10-11`).startOf('day'),
        to: moment(`${year}-10-12`).endOf('day'),
      }]
    }

    if (year === 2023) {
      return [{
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-07-11`).startOf('day'),
        to: moment(`${year}-07-12`).endOf('day'),
      }, {
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-10-10`).startOf('day'),
        to: moment(`${year}-10-11`).endOf('day'),
      }]
    }

    if (year === 2024) {
      return [{
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-07-16`).startOf('day'),
        to: moment(`${year}-07-17`).endOf('day'),
      }, {
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-10-08`).startOf('day'),
        to: moment(`${year}-10-09`).endOf('day'),
      }]
    }

    if (year === 2025) {
      return [{
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-07-08`).startOf('day'),
        to: moment(`${year}-07-09`).endOf('day'),
      }, {
        labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
        from: moment(`${year}-10-08`).startOf('day'),
        to: moment(`${year}-10-09`).endOf('day'),
      }]
    }

    return [{
      labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
      from: moment(`${year}-07-08`).startOf('day'),
      to: moment(`${year}-07-09`).endOf('day'),
    }, {
      labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.PRIME_DEAL_DAYS],
      from: moment(`${year}-10-08`).startOf('day'),
      to: moment(`${year}-10-09`).endOf('day'),
    }]
  },
  [PROMOTION_DAYS.BLACK_WEEK]: (year) => {
    const thanksgiving = getThanksgiving(year)
    // Black Week starts the Friday after Thanksgiving
    const blackWeekStart = thanksgiving.clone().add(1, 'day').startOf('day')
    // Black Week ends on the Friday of the following week
    const blackWeekEnd = thanksgiving.clone().add(6, 'days').endOf('day')

    return [{
      labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.BLACK_WEEK],
      from: blackWeekStart,
      to: blackWeekEnd,
    }]
  },
  [PROMOTION_DAYS.CYBER_MONDAY]: (year) => {
    const thanksgiving = getThanksgiving(year)
    // Cyber Monday is the Monday after Thanksgiving
    const cyberMondayStart = thanksgiving.clone().add(4, 'days').startOf('day')
    const cyberMondayEnd = thanksgiving.clone().add(4, 'days').endOf('day')

    return [{
      labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.CYBER_MONDAY],
      from: cyberMondayStart,
      to: cyberMondayEnd,
    }]
  },
  [PROMOTION_DAYS.SUPER_BOWL]: (year) => ([{
    labelKey: PROMOTION_DATES_TO_LABELS_MAP[PROMOTION_DAYS.SUPER_BOWL],
    from: moment().year(year).month(1).startOf('month').day('Sunday').add(2, 'week').startOf('day'),
    to: moment().year(year).month(1).startOf('month').day('Sunday').add(2, 'week').endOf('day'),
  }]),
}

/**
 * Get promotion dates for a given year range
 * @param intl IntlShape
 * @param startYear Start year
 * @param endYear End year
 * @returns Promotion dates
 */
export const getPromotionDates = (startYear: number, endYear: number) => {
  const promotions = [] as Insights.BaseChartPromotionItem[]

  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)

  years.forEach((year) => {
    Object.keys(PROMOTION_DATES).forEach((promotionKey) => {
      const key = promotionKey as PROMOTION_DAYS
      const promotionsForYear = PROMOTION_DATES[key](year)

      promotionsForYear.forEach((promotion) => {
        promotions.push({
          key,
          labelKey: promotion.labelKey,
          from: Math.floor(promotion.from.utc(true).valueOf() / 1000),
          to: Math.floor(promotion.to.utc(true).valueOf() / 1000),
        })
      })
    })
  })

  return promotions
}

/**
 * Format promotion events
 * @param intl IntlShape
 * @param value Promotion events as label keys
 * @returns Formatted promotion events
 */
export const formatPromotionEvents = (intl: IntlShape, value: PROMOTION_DAYS[]) => {
  return value.map((key: PROMOTION_DAYS) => intl.formatMessage({ id: PROMOTION_DATES_TO_LABELS_MAP[key] })).join(', ')
}

/**
 * Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 * based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
 * @param year Year
 *
 * @returns Easter year, month and day
 */
export const getEasterDateByYear = (year: number) => {
  // eslint-disable-next-line
	var f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4)

  return {
    year: String(year),
    month: month < 10 ? `0${month}` : String(month),
    day: day < 10 ? `0${day}` : String(day),
  }
}

export const PROMOTION_DAYS_LIST_SORTED = PROMOTION_DAYS_LIST.sort((a, b) => {
  const year = new Date().getFullYear()

  const promotionsA = PROMOTION_DATES[a](year)
  const promotionsB = PROMOTION_DATES[b](year)

  const dateA = promotionsA[promotionsA.length - 1].from
  const dateB = promotionsB[promotionsB.length - 1].from

  return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0
})

/**
 * Get promotion details at a given data point
 *
 * @param param
 * @param param.intl IntlShape
 * @param param.data Data point
 * @param param.promotionsVisibility Flag to show promotion days
 * @param param.promotionsToExclude Promotion days to exclude
 * @returns Promotion details at a given data point
 */
export const getPromotionDetailsAtDatapoint = ({
  intl,
  data,
  promotionsVisibility,
  promotionsToExclude = [],
}: {
  data: Insights.BaseChartDatasetItem
  promotionsVisibility: boolean
  promotionsToExclude?: PROMOTION_DAYS[]
  intl: IntlShape
}) => {
  const promotionEvents = (promotionsVisibility ? (data.eventKeys || []) : []) as PROMOTION_DAYS[]
  const filteredPromotionEvents = promotionEvents.filter((event) => !promotionsToExclude.includes(event))
  const hasEvents = filteredPromotionEvents && filteredPromotionEvents.length > 0
  const promotionLabel = intl.formatMessage({ id: filteredPromotionEvents && filteredPromotionEvents.length > 1 ? 'common.promotions' : 'common.promotion' })

  return {
    hasEvents,
    label: promotionLabel,
    formattedEvents: formatPromotionEvents(intl, filteredPromotionEvents),
  }
}
