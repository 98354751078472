import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

import SettingsIcon from '@icons/settings.icon'
import ContextMenuComponent, { ContextMenuAction } from '@base/menus/ContextMenu/ContextMenu.component'
import ManagePromotionsModalContainer from '@containers/modals/manage-promotions-modal'

export interface InsightsChartMenuComponentProps {
  options: ContextMenuAction[]
}

const InsightsChartMenuComponent: React.FC<InsightsChartMenuComponentProps> = ({
  options = [],
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      data-testid={InsightsChartMenuComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(0.5),
      }}
    >
      <ContextMenuComponent
        name='chartSettingsMenu'
        actions={options}
        label={intl.formatMessage({ id: 'insights.chart.menu.settings' })}
        IconComponent={SettingsIcon}
        buttonColor='tertiary'
      />

      <ManagePromotionsModalContainer />
    </Box>
  )
}

export default InsightsChartMenuComponent
