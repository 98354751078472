import { Box, styled } from '@mui/material'

const ChartTooltipRowComponent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  '&:first-of-type': {
    marginTop: '0px',
  },
})

export default ChartTooltipRowComponent
