import {
  takeEvery, put,
  take, call,
  select, takeLatest,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium'
import { DATA_GRIDS, DEFAULT_PAGE_SIZE } from '@constants/data-grid.constants'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { shortcutOptionToDateRange } from '@utils/moment.utils'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { createBacktestingDataGridState, createLiveMonitoringDataGridState } from '@utils/monitor.utils'
import { getDataGridId } from '@utils/data-grid.utils'
import { requestInsightsAbcTotalsAction, requestInsightsPipelineRunInfoAction } from '@redux/modules/insights/insights.actions'

import {
  DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
  DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
  DEFAULT_BACKTEST_TARGET_COLUMN,
  DEFAULT_BACKTEST_PREDICTION_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
  BACKTESTING_TABLE_CONFIG_VERSION,
  LIVE_MONITORING_TABLE_CONFIG_VERSION,
} from '@constants/insights.constants'

import {
  createChartRequestHandler,
  createGridStateChangeHandler,
  createResetViewGenerator,
  createTableRequestHandler,
} from '@utils/insights.utils'

import {
  getSearchTermFromFilterModel,
  convertMUIPageNumberToAPIPageNumber,
  convertMUIPageSizeToAPIPageSize,
  convertMUISortingModelToAPISortReq,
  prepareAPIFilters,
  convertMUIRowSelectionModelToAPIRowFilterReq,
  convertTimeWindowIntoAPIDateRange,
  prepareAPILogicalOperator,
} from '@redux/modules/hera/hera.utils'

import * as API from '@redux/modules/hera/hera.api'

import {
  REQUEST_BACKTESTING_CHART,
  REQUEST_BACKTESTING_TABLE,
  RECEIVE_BACKTESTING_CHART,
  RECEIVE_BACKTESTING_TABLE,
  REQUEST_BACKTESTING,
  RESET_BACKTESTING,
  RECEIVE_BACKTESTING_GRID_STATE_CHANGE,
  REQUEST_BACKTESTING_GRID_STATE_CHANGE,
  REQUEST_BACKTESTING_AVAILABLE_FOLDS,
  RECEIVE_BACKTESTING_AVAILABLE_FOLDS,

  REQUEST_LIVE_MONITORING_CHART,
  REQUEST_LIVE_MONITORING_TABLE,
  RECEIVE_LIVE_MONITORING_CHART,
  RECEIVE_LIVE_MONITORING_TABLE,
  REQUEST_LIVE_MONITORING,
  RESET_LIVE_MONITORING,
  RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE,
  REQUEST_LIVE_MONITORING_GRID_STATE_CHANGE,
  REQUEST_LIVE_MONITORING_FORECAST_HORIZONS,
  RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS,
} from './monitor.action-types'

import {
  startFetchingMonitorAction,
  stopFetchingMonitorAction,

  requestBacktestingChartAction,
  receiveBacktestingChartAction,
  receiveBacktestingTableAction,
  requestBacktestingTableAction,
  requestBacktestingAction,
  receiveBacktestingGridStateChangeAction,
  requestBacktestingGridStateChangeAction,
  requestBacktestingAvailableFoldsAction,
  receiveBacktestingAvailableFoldsAction,

  requestLiveMonitoringChartAction,
  receiveLiveMonitoringChartAction,
  receiveLiveMonitoringTableAction,
  requestLiveMonitoringTableAction,
  requestLiveMonitoringAction,
  requestLiveMonitoringGridStateChangeAction,
  receiveLiveMonitoringGridStateChangeAction,
  requestLiveMonitoringForecastHorizonsAction,
  receiveLiveMonitoringForecastHorizonsAction,
} from './monitor.actions'

import {
  RequestBacktestingActionPayload,
  RequestBacktestingChartActionPayload,
  RequestBacktestingGridStateChangePayload,
  RequestBacktestingTableActionPayload,

  RequestLiveMonitoringActionPayload,
  RequestLiveMonitoringChartActionPayload,
  RequestLiveMonitoringGridStateChangePayload,
  RequestLiveMonitoringTableActionPayload,
} from './monitor.types'

import {
  getBacktestingTable, getBacktestingTableState,
  getBacktestingAvailableFolds, isFetchingBacktestingTable,
  getLiveMonitoringTable, getLiveMonitoringAvailableForecastHorizons,
  getLiveMonitoringTableState, isFetchingLiveMonitoringTable,
} from './monitor.selectors'

export const initialLiveMonitoringGridState = {
  abcFilter: undefined,
  filterModel: {
    items: [],
    logicOperator: 'and',
    quickFilterValues: [''],
  },
  sortModel: [{ field: DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN, sort: 'desc' }],
  aggregationModel: {
    [DEFAULT_LIVE_MONITORING_TARGET_COLUMN]: 'sum',
    [DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN]: 'sum',
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: [DEFAULT_ABS_DEVIATION_COLUMN, DEFAULT_REL_DEVIATION_COLUMN, DEFAULT_LIVE_MONITORING_TARGET_COLUMN, DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN],
  },
  paginationModel: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
  columnVisibilityModel: {},
  internalColumnVisibilityModel: {},
  timeWindow: shortcutOptionToDateRange(DATE_PICKER_SHORTCUT.THIS_YEAR),
  rowSelectionModel: [],
  rowSelectionModelMode: 'exclude',
  liveForecastHorizonOffset: undefined,
} as Monitor.LiveMonitoringGridState

export const initialBacktestingGridState = {
  abcFilter: undefined,
  filterModel: {
    items: [],
    logicOperator: 'and',
    quickFilterValues: [''],
  },
  sortModel: [{ field: DEFAULT_BACKTEST_PREDICTION_COLUMN, sort: 'desc' }],
  aggregationModel: {
    [DEFAULT_BACKTEST_TARGET_COLUMN]: 'sum',
    [DEFAULT_BACKTEST_PREDICTION_COLUMN]: 'sum',
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: [DEFAULT_ABS_DEVIATION_COLUMN, DEFAULT_REL_DEVIATION_COLUMN, DEFAULT_BACKTEST_TARGET_COLUMN, DEFAULT_BACKTEST_PREDICTION_COLUMN],
  },
  paginationModel: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
  columnVisibilityModel: {},
  internalColumnVisibilityModel: {},
  timeWindow: shortcutOptionToDateRange(DATE_PICKER_SHORTCUT.THIS_YEAR),
  rowSelectionModel: [],
  rowSelectionModelMode: 'exclude',
  backtestingFold: undefined,
} as Monitor.BacktestingGridState

export function* requestBacktestingGenerator({ payload }: ActionPayload<RequestBacktestingActionPayload>) {
  try {
    yield put(startFetchingMonitorAction(REQUEST_BACKTESTING))

    yield put(fetchUseCaseAction({
      useCaseId: payload.useCaseId,
      includeDemandProblemDefinition: true,
    }))

    yield take(RECEIVE_USE_CASE)

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const useCaseIdToUse = useCase.demandUseCaseId || payload.useCaseId

    yield put(requestInsightsPipelineRunInfoAction({ useCaseId: useCaseIdToUse }))

    yield put(requestInsightsAbcTotalsAction({ useCaseId: useCaseIdToUse }))

    const tableId = getDataGridId(DATA_GRIDS.BACKTESTING_TABLE, BACKTESTING_TABLE_CONFIG_VERSION, useCaseIdToUse)
    const gridStateFromStorage = createBacktestingDataGridState(tableId, initialBacktestingGridState)

    yield put(requestBacktestingAvailableFoldsAction({ useCaseId: useCaseIdToUse }))

    yield take(RECEIVE_BACKTESTING_AVAILABLE_FOLDS)

    const stateWithFolds: State = yield select()
    const backtestingAvailableFolds: Monitor.MonitorAvailableFolds[] = yield call(getBacktestingAvailableFolds, stateWithFolds)
    const preselectedFoldOption = backtestingAvailableFolds[0]?.offset

    /**
     * If the backtesting fold is not set in the local storage, set it to the first available fold.
     */
    if (gridStateFromStorage.backtestingFold === undefined) {
      Object.assign(gridStateFromStorage, {
        backtestingFold: preselectedFoldOption,
      })
    }

    yield put(requestBacktestingTableAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequestBacktestingTableActionPayload))

    yield put(requestBacktestingChartAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequestBacktestingChartActionPayload))

    yield take(RECEIVE_BACKTESTING_TABLE)

    yield take(RECEIVE_BACKTESTING_CHART)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingMonitorAction(REQUEST_BACKTESTING))
  }
}

export function* requestBacktestingAvailableFoldsGenerator({ payload }: ActionPayload<Hera.ListBacktestFoldsApiRequest>) {
  try {
    yield put(startFetchingMonitorAction(REQUEST_BACKTESTING_AVAILABLE_FOLDS))

    const response: Hera.ListBacktestFoldsApiResponse = yield call(API.getAvailableBacktestingFolds, { useCaseId: payload.useCaseId })

    yield put(receiveBacktestingAvailableFoldsAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveBacktestingAvailableFoldsAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingMonitorAction(REQUEST_BACKTESTING_AVAILABLE_FOLDS))
  }
}

export const requestBacktestingGridStateChangeGenerator = createGridStateChangeHandler<
  RequestBacktestingGridStateChangePayload,
  Monitor.BacktestingGridState,
  Monitor.BacktestingTable
>({
  getGridState: getBacktestingTableState,
  getTableDetails: getBacktestingTable,
  receiveGridStateChangeAction: receiveBacktestingGridStateChangeAction,
  requestTableAction: requestBacktestingTableAction,
  requestChartAction: requestBacktestingChartAction,
  receiveTableAction: receiveBacktestingTableAction,
})

export const requestBacktestingChartGenerator = createChartRequestHandler<
  RequestBacktestingChartActionPayload,
  Monitor.BacktestingChartAPIRequest,
  Monitor.BacktestingChartAPIResponse,
  RequestBacktestingGridStateChangePayload
>({
  includeLastYear: false,
  receiveGridStateChangeType: RECEIVE_BACKTESTING_GRID_STATE_CHANGE,
  receiveTableActionType: RECEIVE_BACKTESTING_TABLE,
  startFetchingAction: () => startFetchingMonitorAction(REQUEST_BACKTESTING_CHART),
  stopFetchingAction: () => stopFetchingMonitorAction(REQUEST_BACKTESTING_CHART),
  getTableFetchingState: isFetchingBacktestingTable,
  requestChartAPI: API.getBacktestingChart,
  receiveChartAction: receiveBacktestingChartAction,
  requestGridStateChangeAction: requestBacktestingGridStateChangeAction,
  convertAPIPayload: (payload, shouldPreselectRows) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    rowFilters: convertMUIRowSelectionModelToAPIRowFilterReq(payload.rowSelectionModel, payload.rowSelectionModelMode, shouldPreselectRows),
    abcFilter: payload.abcFilter,
    latestBacktestFoldOffset: payload.backtestingFold!,
  }),
})

export const requestBacktestingTableGenerator = createTableRequestHandler<
  RequestBacktestingTableActionPayload,
  Monitor.BacktestingTablePaginatedAPIRequest,
  Monitor.BacktestingTablePaginatedAPIResponse,
  Monitor.BacktestingGridState
>({
  tableName: DATA_GRIDS.BACKTESTING_TABLE,
  tableVersion: BACKTESTING_TABLE_CONFIG_VERSION,

  startFetchingAction: () => startFetchingMonitorAction(REQUEST_BACKTESTING_TABLE),
  stopFetchingAction: () => stopFetchingMonitorAction(REQUEST_BACKTESTING_TABLE),
  getInitialGridState: (payload) => {
    return Object.assign(initialBacktestingGridState, {
      backtestingFold: payload.backtestingFold,
    })
  },
  createGridState: (tableId, finalState) => createBacktestingDataGridState(tableId, finalState),
  requestTableAPI: API.getBacktestingTable,

  receiveTableAction: receiveBacktestingTableAction,
  retryAction: requestBacktestingAction,
  convertAPIPayload: (payload) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    pageSize: convertMUIPageSizeToAPIPageSize(payload.paginationModel?.pageSize),
    pageNumber: convertMUIPageNumberToAPIPageNumber(payload.paginationModel?.page),
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    searchTerm: getSearchTermFromFilterModel(payload.filterModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    abcFilter: payload.abcFilter,
    latestBacktestFoldOffset: payload.backtestingFold!,
  }),
})

export const resetBacktestingGenerator = createResetViewGenerator({
  receiveTableAction: receiveBacktestingTableAction,
  receiveChartAction: receiveBacktestingChartAction,
})

export function* requestLiveMonitoringGenerator({ payload }: ActionPayload<RequestLiveMonitoringActionPayload>) {
  try {
    yield put(startFetchingMonitorAction(REQUEST_LIVE_MONITORING))

    yield put(fetchUseCaseAction({
      useCaseId: payload.useCaseId,
      includeDemandProblemDefinition: true,
    }))

    yield take(RECEIVE_USE_CASE)

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const useCaseIdToUse = useCase.demandUseCaseId || payload.useCaseId

    yield put(requestInsightsPipelineRunInfoAction({ useCaseId: useCaseIdToUse }))

    yield put(requestInsightsAbcTotalsAction({ useCaseId: useCaseIdToUse }))

    const tableId = getDataGridId(DATA_GRIDS.LIVE_MONITORING_TABLE, LIVE_MONITORING_TABLE_CONFIG_VERSION, useCaseIdToUse)
    const gridStateFromStorage = createLiveMonitoringDataGridState(tableId, initialLiveMonitoringGridState)

    yield put(requestLiveMonitoringForecastHorizonsAction({ useCaseId: useCaseIdToUse }))

    yield take(RECEIVE_LIVE_MONITORING_FORECAST_HORIZONS)

    const stateWithOffsets: State = yield select()
    const liveForecastHorizonOffsets: Monitor.MonitorAvailableFolds[] = yield call(getLiveMonitoringAvailableForecastHorizons, stateWithOffsets)
    const preselectedForecastHorizonOffset = liveForecastHorizonOffsets[0]?.offset

    /**
     * If the live forecast horizon offset is not set in the local storage, set it to the first available offset.
     */
    if (gridStateFromStorage.liveForecastHorizonOffset === undefined) {
      Object.assign(gridStateFromStorage, {
        liveForecastHorizonOffset: preselectedForecastHorizonOffset,
      })
    }

    yield put(requestLiveMonitoringTableAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequestLiveMonitoringTableActionPayload))

    yield put(requestLiveMonitoringChartAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequestLiveMonitoringChartActionPayload))

    yield take(RECEIVE_LIVE_MONITORING_TABLE)

    yield take(RECEIVE_LIVE_MONITORING_CHART)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingMonitorAction(REQUEST_LIVE_MONITORING))
  }
}

export function* requestLiveMonitoringForecastHorizonsGenerator({ payload }: ActionPayload<Hera.ListForecastHorizonsApiRequest>) {
  try {
    yield put(startFetchingMonitorAction(REQUEST_LIVE_MONITORING_FORECAST_HORIZONS))

    const response: Hera.ListForecastHorizonsApiResponse = yield call(API.getLiveMonitoringForecastHorizons, { useCaseId: payload.useCaseId })

    yield put(receiveLiveMonitoringForecastHorizonsAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(receiveLiveMonitoringForecastHorizonsAction([]))

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingMonitorAction(REQUEST_LIVE_MONITORING_FORECAST_HORIZONS))
  }
}

export const requestLiveMonitoringGridStateChangeGenerator = createGridStateChangeHandler<
  RequestLiveMonitoringGridStateChangePayload,
  Monitor.LiveMonitoringGridState,
  Monitor.LiveMonitoringTable
>({
  getGridState: getLiveMonitoringTableState,
  getTableDetails: getLiveMonitoringTable,
  receiveGridStateChangeAction: receiveLiveMonitoringGridStateChangeAction,
  requestTableAction: requestLiveMonitoringTableAction,
  requestChartAction: requestLiveMonitoringChartAction,
  receiveTableAction: receiveLiveMonitoringTableAction,
})

export const requestLiveMonitoringChartGenerator = createChartRequestHandler<
  RequestLiveMonitoringChartActionPayload,
  Monitor.LiveMonitoringChartAPIRequest,
  Monitor.LiveMonitoringChartAPIResponse,
  RequestLiveMonitoringGridStateChangePayload
>({
  includeLastYear: false,
  receiveGridStateChangeType: RECEIVE_LIVE_MONITORING_GRID_STATE_CHANGE,
  receiveTableActionType: RECEIVE_LIVE_MONITORING_TABLE,
  startFetchingAction: () => startFetchingMonitorAction(REQUEST_LIVE_MONITORING_CHART),
  stopFetchingAction: () => stopFetchingMonitorAction(REQUEST_LIVE_MONITORING_CHART),
  getTableFetchingState: isFetchingLiveMonitoringTable,
  requestChartAPI: API.getLiveMonitoringChart,
  receiveChartAction: receiveLiveMonitoringChartAction,
  requestGridStateChangeAction: requestLiveMonitoringGridStateChangeAction,
  convertAPIPayload: (payload, shouldPreselectRows) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    rowFilters: convertMUIRowSelectionModelToAPIRowFilterReq(payload.rowSelectionModel, payload.rowSelectionModelMode, shouldPreselectRows),
    abcFilter: payload.abcFilter,
    latestLiveForecastHorizonOffset: payload.liveForecastHorizonOffset!,
  }),
})

export const requestLiveMonitoringTableGenerator = createTableRequestHandler<
  RequestLiveMonitoringTableActionPayload,
  Monitor.LiveMonitoringTablePaginatedAPIRequest,
  Monitor.LiveMonitoringTablePaginatedAPIResponse,
  Monitor.LiveMonitoringGridState
>({
  tableName: DATA_GRIDS.LIVE_MONITORING_TABLE,
  tableVersion: LIVE_MONITORING_TABLE_CONFIG_VERSION,

  startFetchingAction: () => startFetchingMonitorAction(REQUEST_LIVE_MONITORING_TABLE),
  stopFetchingAction: () => stopFetchingMonitorAction(REQUEST_LIVE_MONITORING_TABLE),
  getInitialGridState: (payload) => {
    return Object.assign(initialLiveMonitoringGridState, {
      liveForecastHorizonOffset: payload.liveForecastHorizonOffset,
    })
  },
  createGridState: (tableId, finalState) => createLiveMonitoringDataGridState(tableId, finalState),
  requestTableAPI: API.getLiveMonitoringTable,
  receiveTableAction: receiveLiveMonitoringTableAction,
  retryAction: requestLiveMonitoringAction,
  convertAPIPayload: (payload) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    pageSize: convertMUIPageSizeToAPIPageSize(payload.paginationModel?.pageSize),
    pageNumber: convertMUIPageNumberToAPIPageNumber(payload.paginationModel?.page),
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    searchTerm: getSearchTermFromFilterModel(payload.filterModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    abcFilter: payload.abcFilter,
    latestLiveForecastHorizonOffset: payload.liveForecastHorizonOffset!,
  }),
})

export const resetLiveMonitoringGenerator = createResetViewGenerator({
  receiveTableAction: receiveLiveMonitoringTableAction,
  receiveChartAction: receiveLiveMonitoringChartAction,
})

export function* watchRequestBacktestingAvailableFolds() {
  yield takeEvery(REQUEST_BACKTESTING_AVAILABLE_FOLDS, requestBacktestingAvailableFoldsGenerator)
}

export function* watchRequestBacktestingGridStateChange() {
  yield takeLatest(REQUEST_BACKTESTING_GRID_STATE_CHANGE, requestBacktestingGridStateChangeGenerator)
}

export function* watchResetBacktesting() {
  yield takeEvery(RESET_BACKTESTING, resetBacktestingGenerator)
}

export function* watchRequestBacktestingChart() {
  yield takeLatest(REQUEST_BACKTESTING_CHART, requestBacktestingChartGenerator)
}

export function* watchRequestBacktestingTable() {
  yield takeLatest(REQUEST_BACKTESTING_TABLE, requestBacktestingTableGenerator)
}

export function* watchRequestBacktesting() {
  yield takeEvery(REQUEST_BACKTESTING, requestBacktestingGenerator)
}

export function* watchRequestLiveMonitoringForecastHorizons() {
  yield takeEvery(REQUEST_LIVE_MONITORING_FORECAST_HORIZONS, requestLiveMonitoringForecastHorizonsGenerator)
}

export function* watchRequestLiveMonitoringGridStateChange() {
  yield takeLatest(REQUEST_LIVE_MONITORING_GRID_STATE_CHANGE, requestLiveMonitoringGridStateChangeGenerator)
}

export function* watchResetLiveMonitoring() {
  yield takeEvery(RESET_LIVE_MONITORING, resetLiveMonitoringGenerator)
}

export function* watchRequestLiveMonitoringChart() {
  yield takeLatest(REQUEST_LIVE_MONITORING_CHART, requestLiveMonitoringChartGenerator)
}

export function* watchRequestLiveMonitoringTable() {
  yield takeLatest(REQUEST_LIVE_MONITORING_TABLE, requestLiveMonitoringTableGenerator)
}

export function* watchRequestLiveMonitoring() {
  yield takeEvery(REQUEST_LIVE_MONITORING, requestLiveMonitoringGenerator)
}

export default {
  watchRequestBacktesting,
  watchRequestBacktestingTable,
  watchRequestBacktestingChart,
  watchRequestBacktestingGridStateChange,
  watchResetBacktesting,
  watchRequestBacktestingAvailableFolds,

  watchRequestLiveMonitoring,
  watchRequestLiveMonitoringTable,
  watchRequestLiveMonitoringChart,
  watchRequestLiveMonitoringGridStateChange,
  watchResetLiveMonitoring,
  watchRequestLiveMonitoringForecastHorizons,
}
