import React, {
  useCallback,
  useMemo,
} from 'react'

import { IntlShape, useIntl } from 'react-intl'
import isEqual from 'lodash.isequal'

import {
  GRID_AGGREGATION_FUNCTIONS,
  GridFilterModel,
  GridPaginationModel,
  GridRowGroupingModel,
  GridSortModel,
  GridAggregationModel,
  GridColumnVisibilityModel,
  GridPinnedColumnFields,
  GridRowClassNameParams,
  gridClasses,
  GridRowSelectionModel,
  GridRowParams,
  GridCallbackDetails,
  GridColDef,
  GridRowId,
  GridColumnGroupingModel,
} from '@mui/x-data-grid-premium'

import DataGridComponent from '@base/datagrid/data-grid'

import { useDispatch } from '@redux/hooks'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { DATA_GRIDS, DATA_GRID_CUSTOM_CLASS_NAMES } from '@constants/data-grid.constants'
import { filterEmptyFilterItems } from '@utils/data-grid.utils'
import { generateGroupingRowClassName } from '@utils/insights.utils'
import { INSIGHTS_COLOR_WAY, INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPING_MODE } from '@constants/insights.constants'

import InsightsGridHeaderCheckboxComponent, {
  InsightsGridHeaderCheckboxGridCallbackDetails,
} from '@components/insights/InsightsGridHeaderCheckbox/InsightsGridHeaderCheckbox.component'

import { RequestAnalyzeGridStateChangePayload } from '@redux/modules/analyze/analyze.types'
import { RequestBacktestingGridStateChangePayload } from '@redux/modules/monitor/monitor.types'
import DataGridSkeletonComponent from '@base/datagrid/data-grid-skeleton/DataGridSkeleton.component'

export interface InsightsTableComponentProps {
  /**
   * The id of the table
   */
  tableId: string
  /**
   * The columns of the table
   */
  columns: Insights.SimplifiedGridColumnDefinition[]
  /**
   * The rows of the table
   */
  rows: Hera.APITableRow[]
  /**
   * The number of rows in the current page
   */
  rowCount: number
  /**
   * The total number of rows
   */
  totalRowCount: number
  /**
   * The grid state of the table
   */
  gridState: Insights.BaseGridState | null | undefined
  /**
   * Whether the table is fetching data
   */
  isFetching: boolean
  /**
   * The default sorting column
   */
  defaultSortingColumn: string
  /**
   * The columns that should always be visible
   */
  alwaysVisibleColumns: string[]
  /**
   * The model for the column grouping
   */
  columnGroupingModel?: GridColumnGroupingModel
  /**
   * The action to request a grid state change
   * @param payload The payload to request a grid state change
   * @returns void
   */
  requestGridStateChangeAction: (payload: Partial<RequestAnalyzeGridStateChangePayload | RequestBacktestingGridStateChangePayload>) => void
  /**
   * The transformer function for the columns
   * @param intl The intl object
   * @param columns The columns to transform
   * @returns The transformed columns
   */
  columnTransformer: (intl: IntlShape, columns: Insights.SimplifiedGridColumnDefinition[]) => GridColDef[]
  /**
   * The transformer function for the aggregation model. Sometimes we aggregate multiple columns at once, so we need to transform the aggregation model
   * @param prevAggregationModel Previous aggregation model
   * @param newAggregationModel New aggregation model
   * @returns The transformed aggregation model
   */
  aggregationModelTransformer?: (prevAggregationModel: GridAggregationModel, newAggregationModel: GridAggregationModel) => GridAggregationModel
}

const InsightsTableComponent: React.FC<InsightsTableComponentProps> = ({
  tableId,
  rows,
  rowCount,
  totalRowCount,
  columns,
  gridState,
  isFetching,
  defaultSortingColumn,
  alwaysVisibleColumns,
  columnGroupingModel,
  columnTransformer,
  aggregationModelTransformer,
  requestGridStateChangeAction,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const aggregationFunctions = useMemo(() => {
    return Object.fromEntries(
      Object.entries(GRID_AGGREGATION_FUNCTIONS).filter(
        ([name]) => name !== 'size',
      ),
    )
  }, [])

  const processedColumns = useMemo(() => {
    return columnTransformer(intl, columns)
  }, [intl, columns, columnTransformer])

  const hasGroupingEnabled = useMemo(() => {
    return gridState && gridState.groupingModel && gridState.groupingModel.length > 0
  }, [gridState])

  const selectedRows = useMemo(() => {
    return gridState?.rowSelectionModel || []
  }, [gridState])

  const useReverseCheckboxes = useMemo(() => {
    return Boolean(gridState?.rowSelectionModelMode === 'exclude')
  }, [gridState])

  const slotProps = useMemo(() => ({
    noRowsOverlay: {
      labels: [intl.formatMessage({ id: 'insights.table.empty' })],
    },
    pagination: {
      disabledPagination: isFetching,
    } as any,
    toolbar: {
      withExport: false,
      disableColumnVisibilityPanelWhenGrouping: true,
    },
    footer: {
      selectedRowCountAlwaysVisible: true,
    },
    baseCheckbox: {
      reversed: useReverseCheckboxes,
      disabledHint: intl.formatMessage({
        id: 'insights.table.checkboxDisabledHint',
      }, {
        num: INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPING_MODE,
      }),
    },
  }), [
    intl,
    useReverseCheckboxes,
    isFetching,
  ])

  const onPaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    dispatch(
      requestGridStateChangeAction({
        paginationModel: newPaginationModel,
        resetPagination: false,
        requestChartData: false,
      }),
    )
  }

  const onFilterModelChange = (newFilterModel: GridFilterModel) => {
    const newFilterList = newFilterModel.items.filter(filterEmptyFilterItems)
    const prevFilterList = gridState?.filterModel?.items.filter(filterEmptyFilterItems)
    const newLogicOperator = newFilterModel.logicOperator
    const prevLogicOperator = gridState?.filterModel?.logicOperator

    const newSearchQuery = newFilterModel.quickFilterValues
    const prevSearchQuery = gridState?.filterModel?.quickFilterValues
    const hasFilterChanged = !isEqual(newFilterList, prevFilterList) || (newLogicOperator !== prevLogicOperator)
    const hasSearchQueryChanged = !isEqual(newSearchQuery, prevSearchQuery)
    const shouldRequestTableData = hasFilterChanged || hasSearchQueryChanged

    dispatch(
      requestGridStateChangeAction({
        filterModel: newFilterModel,
        requestTableData: shouldRequestTableData,
        requestChartData: hasFilterChanged,
      }),
    )
  }

  const onSortModelChange = (newSortModel: GridSortModel) => {
    dispatch(
      requestGridStateChangeAction({
        sortModel: newSortModel,
      }),
    )
  }

  const onRowGroupingModelChange = (newGroupModel: GridRowGroupingModel) => {
    const sortingModel = gridState?.sortModel || []
    const latestInternalColumnVisibilityModel = gridState?.internalColumnVisibilityModel || {}

    const newVisibilityModel: {
      [key: string]: boolean
    } = {}

    if (newGroupModel.length > 0) {
      columns.forEach((column) => {
        newVisibilityModel[column.field] = newGroupModel.includes(column.field) || alwaysVisibleColumns.includes(column.field)
      })
    } else {
      Object.assign(newVisibilityModel, latestInternalColumnVisibilityModel)
    }

    const request = {
      groupingModel: newGroupModel,
      columnVisibilityModel: newVisibilityModel,
      resetRowSelection: newGroupModel.length === 0,
      preselectRows: newGroupModel.length > 0,
    }

    if (sortingModel.length > 0) {
      sortingModel.forEach((sort) => {
        /**
          * In case the sorting column is not in the grouping model
          * We need to add the default sorting column
          */
        if (!(newGroupModel.includes(sort.field) || sort.field === defaultSortingColumn)) {
          Object.assign(request, {
            sortModel: [{ field: defaultSortingColumn, sort: 'desc' }],
          })
        }
      })
    }

    dispatch(
      requestGridStateChangeAction(request),
    )
  }

  const onAggregationModelChange = (newAggregationModel: GridAggregationModel) => {
    const transformedAggregationModel = aggregationModelTransformer ? aggregationModelTransformer(gridState?.aggregationModel!, newAggregationModel) : newAggregationModel

    dispatch(
      requestGridStateChangeAction({
        aggregationModel: transformedAggregationModel,
      }),
    )
  }

  const onColumnVisibilityModelChange = (newColumnVisibilityModel: GridColumnVisibilityModel) => {
    dispatch(
      requestGridStateChangeAction({
        columnVisibilityModel: newColumnVisibilityModel,
        requestTableData: false,
        requestChartData: false,
        resetPagination: false,
      }),
    )
  }

  const onPinnedColumnsChange = (newPinnedColumns: GridPinnedColumnFields) => {
    dispatch(
      requestGridStateChangeAction({
        pinnedColumns: newPinnedColumns,
        requestTableData: false,
        requestChartData: false,
        resetPagination: false,
      }),
    )
  }

  const getRowClassName = (params: GridRowClassNameParams) => {
    if (hasGroupingEnabled) {
      const selectionIndex = selectedRows.findIndex((rowId) => rowId === params.row._row_id)

      if (selectionIndex !== -1) {
        return generateGroupingRowClassName(selectionIndex)
      }
    }

    return params.indexRelativeToCurrentPage % 2 === 0 ? DATA_GRID_CUSTOM_CLASS_NAMES.EVEN : DATA_GRID_CUSTOM_CLASS_NAMES.ODD
  }

  const isRowSelectable = useCallback((params: GridRowParams) => {
    if (!hasGroupingEnabled) {
      return true
    }

    return selectedRows.length < INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPING_MODE || selectedRows.includes(params.row._row_id)
  }, [hasGroupingEnabled, selectedRows])

  const onRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
    const { mode } = details as InsightsGridHeaderCheckboxGridCallbackDetails

    const payload = {
      rowSelectionModel: newRowSelectionModel as GridRowId[],
      requestTableData: false,
      requestChartData: true,
      resetPagination: false,
    }

    /**
     * Mode is only set when the header checkbox is clicked
     */
    if (mode) {
      Object.assign(payload, {
        rowSelectionModelMode: mode,
      })
    }

    dispatch(
      requestGridStateChangeAction(payload),
    )
  }

  const sxStyles = useMemo(() => {
    const styles: {
      [key: string]: any
    } = {}

    /**
     * Generate classnames definitions for each row in the table
     * which will be used to apply the color to the row
     */
    Object.keys(INSIGHTS_COLOR_WAY).forEach((row, index) => {
      const colors = INSIGHTS_COLOR_WAY[index]
      const className = generateGroupingRowClassName(index)
      const key = `& .${gridClasses.row}.${className}`

      styles[key] = {
        fontWeight: '500',
        color: colors.base,
        backgroundColor: colors.background,
        '&:hover, &.Mui-hovered': {
          backgroundColor: `${colors.hover} !important`,
          '& .MuiDataGrid-cell--pinnedRight': {
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-cell--pinnedLeft': {
            backgroundColor: 'inherit',
          },
        },
      }
    })

    return {
      [`&.${gridClasses.root}`]: {
        ...styles,
      },
    }
  }, [])

  if (!gridState) {
    return (
      <DataGridSkeletonComponent />
    )
  }

  return (
    <DataGridComponent
      id={tableId}
      name={DATA_GRIDS.ANALYZE_TABLE}
      loading={isFetching}
      rows={rows}
      columns={processedColumns}
      getRowId={(row) => row._row_id}
      aggregationFunctions={aggregationFunctions}
      rounded={true}
      autoHeight={true}
      enableGroupExpand={false}
      disableMultipleColumnsSorting={true}
      sortingMode='server'
      filterMode='server'
      paginationMode='server'
      groupingMode='server'
      aggregationMode='server'
      rowSelectionMode='server'
      rowCount={rowCount}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      onFilterModelChange={onFilterModelChange}
      onRowGroupingModelChange={onRowGroupingModelChange}
      onAggregationModelChange={onAggregationModelChange}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onPinnedColumnsChange={onPinnedColumnsChange}
      onRowSelectionModelChange={onRowSelectionModelChange}
      rowSelectionModel={gridState.rowSelectionModel}
      groupingModel={gridState.groupingModel}
      aggregationModel={gridState.aggregationModel}
      paginationModel={gridState.paginationModel}
      columnGroupingModel={columnGroupingModel}
      filterModel={gridState.filterModel}
      sortModel={gridState.sortModel}
      columnVisibilityModel={gridState.columnVisibilityModel}
      pinnedColumns={gridState.pinnedColumns}
      getAggregationPosition={() => null}
      disableVirtualization={true}
      autosizeOnMount={true}
      getRowClassName={getRowClassName}
      sx={sxStyles}
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      hideFooterSelectedRowCount={false}
      keepNonExistentRowsSelected={true}
      DataGridHeaderCheckboxComponent={InsightsGridHeaderCheckboxComponent}
      isRowSelectable={isRowSelectable}
      slotProps={slotProps}
      columnGroupHeaderHeight={40}
      customLocales={{
        footerRowSelected: (v: number) => {
          const selectedRowsCount: number = useReverseCheckboxes ? ((totalRowCount || 0) - v) : v

          if (isFetching && hasGroupingEnabled) {
            return ''
          }

          return intl.formatMessage({
            id: 'insights.table.rowsSelected',
          }, {
            selectedRowsCount,
            formattedCount: defaultNumberFormatter(selectedRowsCount),
          })
        },
      }}
    />
  )
}

export default InsightsTableComponent
