import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'
import { getAnalyzeChart, getAnalyzeTable, isFetchingAnalyzeChart } from '@redux/modules/analyze/analyze.selectors'
import { getLastYearVisibility, getPromotionsToExclude, getPromotionsVisibility } from '@redux/modules/insights/insights.selectors'
import { requestLastYearVisibilityChangeAction, requestPromotionsVisibilityChangeAction } from '@redux/modules/insights/insights.actions'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { ContextMenuAction } from '@base/menus/ContextMenu/ContextMenu.component'
import { MANAGE_PROMOTIONS_MODAL_NAME } from '@constants/modals.constants'

import {
  DEFAULT_ANALYZE_PREDICTION_FIELD, DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX,
  DEFAULT_ANALYZE_TARGET_FIELD, INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD_PREFIX,
} from '@constants/insights.constants'

import InsightsChartComponent from '@components/insights/InsightsChart'

const AnalyzeChartContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const analyzeTable = useSelector((state) => getAnalyzeTable(state))
  const analyzeChart = useSelector((state) => getAnalyzeChart(state))
  const isFetching = useSelector((state) => isFetchingAnalyzeChart(state))
  const promotionsVisibility = useSelector((state) => getPromotionsVisibility(state))
  const promotionsToExclude = useSelector((state) => getPromotionsToExclude(state))
  const lastYearVisibility = useSelector((state) => getLastYearVisibility(state))

  const handleLastYearToggle = useCallback(() => {
    dispatch(
      requestLastYearVisibilityChangeAction(!lastYearVisibility),
    )
  }, [dispatch, lastYearVisibility])

  const handleShowEventsToggle = useCallback(() => {
    dispatch(
      requestPromotionsVisibilityChangeAction(!promotionsVisibility),
    )
  }, [dispatch, promotionsVisibility])

  const handleManageEvents = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(MANAGE_PROMOTIONS_MODAL_NAME),
    )
  }, [dispatch])

  const {
    gridState,
  } = analyzeTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
    promotionDays,
  } = analyzeChart

  const chartSettingsActions: ContextMenuAction[] = [{
    name: 'showLastYear',
    type: 'toggle',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.showLastYear' }),
    disabled: false,
    checked: lastYearVisibility,
    handler: handleLastYearToggle,
  }, {
    name: 'showEvents',
    type: 'toggle',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.showEvents' }),
    disabled: false,
    checked: promotionsVisibility,
    handler: handleShowEventsToggle,
  }, {
    name: 'manageEvents',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.manageEvents' }),
    disabled: false,
    handler: handleManageEvents,
  }]

  return (
    <Box
      data-testid={AnalyzeChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        gridState={gridState}
        targetName={targetName}
        targetUnit={targetUnit}
        promotionDays={promotionDays}
        isFetching={isFetching}
        promotionsVisibility={promotionsVisibility}
        promotionsToExclude={promotionsToExclude}
        chartSettingsActions={chartSettingsActions}
        lastYearVisibility={lastYearVisibility}
        groupingModeAttributes={{
          predictionPayloadKeyPrefix: DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX,
          lastYearTargetPayloadKeyPrefix: INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD_PREFIX,
        }}
        regularModeAttributes={{
          targetPayloadKey: DEFAULT_ANALYZE_TARGET_FIELD,
          predictionPayloadKey: DEFAULT_ANALYZE_PREDICTION_FIELD,
          lastYearTargetPayloadKey: INSIGHTS_DEFAULT_LAST_YEAR_TARGET_FIELD_PREFIX,
        }}
      />
    </Box>
  )
}

export default AnalyzeChartContainer
