import React from 'react'
import get from 'lodash.get'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { DEFAULT_TOOLTIP_DATE_FORMAT, formatTimestamp, ISO_CALENDAR_WEEK_FORMAT } from '@utils/moment.utils'
import { PROMOTION_DAYS } from '@constants/promotions.constants'
import { getPromotionDetailsAtDatapoint } from '@utils/promotions.utils'
import { defaultInsightsTooltipValueFormatter } from '@utils/insights.utils'
import { useTheme } from '@mui/material'

import ChartTooltipContainerComponent from '@components/charts/ChartTooltipContainer'
import ChartTooltipLegendItemComponent from '@components/charts/ChartTooltipLegendItem'
import ChartTooltipValueItemComponent from '@components/charts/ChartTooltipValueItem'
import ChartTooltipRowComponent from '@components/charts/ChartTooltipRow'

import { INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR } from '@constants/insights.constants'

export interface InsightsChartTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * Flag to indicate if the tooltip is active
   */
  active?: boolean
  /**
   * Flag to indicate if the promotion days should be shown
   */
  promotionsVisibility?: boolean
  /**
   * Flag to indicate if the last year value should be shown
   */
  lastYearVisibility?: boolean
  /**
   * Name of the target
   */
  targetName?: string
  /**
   * Key for the target payload
   */
  targetPayloadKey: string
  /**
   * Prefix for the prediction key
   */
  predictionPayloadKey: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationPayloadKey?: string
  /**
   * Key for the last year target payload
   */
  lastYearTargetPayloadKey?: string
  /**
   * Data to be displayed in the tooltip
   */
  payload?: Insights.BaseChartDatasetItem[]
  /**
   * Flag to show promotion days
   */
  promotionsToExclude?: PROMOTION_DAYS[]
}

const InsightsChartTooltipComponent: React.FC<InsightsChartTooltipProps> = ({
  active, payload,
  promotionsToExclude = [],
  targetName,
  promotionsVisibility = true,
  lastYearVisibility = true,
  targetPayloadKey = '',
  lastYearTargetPayloadKey = '',
  predictionPayloadKey = '',
  absDeviationPayloadKey = '',
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const data: Insights.BaseChartDatasetItem = get(payload, '[0].payload', {})

  const {
    formattedEvents,
    hasEvents,
    label: promotionLabel,
  } = getPromotionDetailsAtDatapoint({
    data,
    promotionsVisibility,
    promotionsToExclude,
    intl,
  })

  if (active && payload && payload.length) {
    const date = get(data, 'date', 0)
    const formattedDate = formatTimestamp(date, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT)
    const formattedCalendarWeek = formatTimestamp(date, intl.locale, ISO_CALENDAR_WEEK_FORMAT)

    const absDeviationValue = get(data, absDeviationPayloadKey, null)
    const formattedAbsDeviationValue = defaultInsightsTooltipValueFormatter(intl, absDeviationValue, { showIntervals: false })

    const targetValue = data.virtualTarget ? null : get(data, targetPayloadKey, null)
    const formattedTargetValue = defaultInsightsTooltipValueFormatter(intl, targetValue, { showIntervals: true })

    const predictionValue = get(data, predictionPayloadKey, null)
    const formattedPredictionValue = defaultInsightsTooltipValueFormatter(intl, predictionValue, { showIntervals: true })

    return (
      <ChartTooltipContainerComponent
        data-testid={InsightsChartTooltipComponent.name}
      >
        <ChartTooltipRowComponent>
          <ChartTooltipLegendItemComponent
            label={intl.formatMessage({ id: 'insights.chart.x.title' })}
          />

          <ChartTooltipValueItemComponent
            value={formattedDate}
          />
        </ChartTooltipRowComponent>

        <ChartTooltipRowComponent
          sx={{ mb: hasEvents ? 0 : 1 }}
        >
          <ChartTooltipLegendItemComponent
            label={intl.formatMessage({ id: 'insights.chart.x.calendarWeek' })}
          />

          <ChartTooltipValueItemComponent
            value={formattedCalendarWeek}
          />
        </ChartTooltipRowComponent>

        {
          hasEvents ? (
            <ChartTooltipRowComponent
              sx={{ mb: 1 }}
            >
              <ChartTooltipLegendItemComponent
                label={promotionLabel}
              />

              <ChartTooltipValueItemComponent
                value={formattedEvents}
              />
            </ChartTooltipRowComponent>
          ) : (
            null
          )
        }

        <ChartTooltipRowComponent>
          <ChartTooltipLegendItemComponent
            label={intl.formatMessage({
              id: 'insights.chart.actualResults',
            }, {
              name: targetName,
            })}
            color={theme.palette.new.black}
          />

          <ChartTooltipValueItemComponent
            value={formattedTargetValue}
          />
        </ChartTooltipRowComponent>

        {
          lastYearVisibility && lastYearTargetPayloadKey ? (
            <ChartTooltipRowComponent>
              <ChartTooltipLegendItemComponent
                label={intl.formatMessage({
                  id: 'insights.chart.lastYearResults',
                }, {
                  name: targetName,
                })}
                color={INSIHGTS_DEFAULT_LAST_YEAR_LINE_COLOR}
              />

              <ChartTooltipValueItemComponent
                value={defaultInsightsTooltipValueFormatter(intl, get(data, lastYearTargetPayloadKey, null), { showIntervals: true })}
              />
            </ChartTooltipRowComponent>
          ) : (
            null
          )
        }

        <ChartTooltipRowComponent>
          <ChartTooltipLegendItemComponent
            label={intl.formatMessage({
              id: 'insights.chart.predictionResults',
            }, {
              name: targetName,
            })}
            color={theme.palette.new.pink}
          />

          <ChartTooltipValueItemComponent
            value={formattedPredictionValue}
          />
        </ChartTooltipRowComponent>

        {
          absDeviationPayloadKey ? (
            <ChartTooltipRowComponent>
              <ChartTooltipLegendItemComponent
                label={intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' })}
              />

              <ChartTooltipValueItemComponent
                value={formattedAbsDeviationValue}
              />
            </ChartTooltipRowComponent>
          ) : (
            null
          )
        }

      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default InsightsChartTooltipComponent
