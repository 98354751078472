export const REQUEST_PARAMETER_OVERVIEW = 'REQUEST_PARAMETER_OVERVIEW'
export const REQUEST_PARAMETER = 'REQUEST_PARAMETER'
export const RECEIVE_PARAMETER = 'RECEIVE_PARAMETER'

export const CREATE_PARAMETER = 'CREATE_PARAMETER'
export const UPDATE_PARAMETER = 'UPDATE_PARAMETER'
export const DELETE_PARAMETER = 'DELETE_PARAMETER'

export const REQUEST_GROUPING_OVERVIEW = 'REQUEST_GROUPING_OVERVIEW'
export const REQUEST_GROUPING = 'REQUEST_GROUPING'
export const RECEIVE_GROUPING = 'RECEIVE_GROUPING'

export const CREATE_GROUPING = 'CREATE_GROUPING'
export const UPDATE_GROUPING = 'UPDATE_GROUPING'
export const DELETE_GROUPING = 'DELETE_GROUPING'

export const CREATE_EVALUATION_PROFILE = 'CREATE_EVALUATION_PROFILE'
export const UPDATE_EVALUATION_PROFILE = 'UPDATE_EVALUATION_PROFILE'
export const DELETE_EVALUATION_PROFILE = 'DELETE_EVALUATION_PROFILE'

export const START_FETCHING_PARAMETER = 'START_FETCHING_PARAMETER'
export const STOP_FETCHING_PARAMETER = 'STOP_FETCHING_PARAMETER'
