import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'
import { Box, Typography, useTheme } from '@mui/material'
import { getFormattedCurrentDate } from '@utils/moment.utils'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import * as API from '@redux/modules/hermes/hermes.api'
import TextFieldComponent from '@base/forms/TextField'
import HelpButtonComponent from '@base/buttons/HelpButton'
import ConnectorConfigurationContainer from '@components/connectors/ConnectorConfiguration'
import VideoBlockComponent from '@components/connectors/VideoBlock'

import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { getAvailableSources, isFetching as isFetchingHermes } from '@redux/modules/hermes/hermes.selectors'
import { fetchConnectionsWithDetailsAction, requestAddConnectionAction, requestUpdateConnectionAction } from '@redux/modules/hermes/hermes.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { UpdateConnectionPayload } from '@redux/modules/hermes/hermes.types'
import { SOURCE_TYPES } from '@constants/flow.constants'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

import {
  AUTH_DATA_SOURCE_MODAL_NAME,
  DATA_SOURCES_MODAL_NAME,
  DATA_SOURCE_CONNECTED_MODAL_NAME,
} from '@constants/modals.constants'

import {
  getConfigFromSource, checkifOAuthRequired,
  isOAuthDone, encodeStreamName,
} from '@utils/connectors.utils'

import validateAuthDataModal from './AuthDataSourceModal.validations'

export interface AuthDataSourceModalDetails extends Common.ModalDetails {
  key: SOURCE_TYPES
  icon: React.ElementType
  title: string
  help: string
  sourceId: string
  description: string
  documentationLink: string
  videoLink: string
  connectionId: string
  videoHeight: string
}

const initialFormValues = {
  sourceId: '',
  name: '',
  configuration: {},
  streams: {},
} as Hermes.ConnectionFormItem

const AuthDataSourceModalContainer: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [formValues, setFormValues] = useState(initialFormValues)
  const [loading, setLoading] = useState(false)

  const showToast = bindActionCreators(changeToastAction, dispatch)
  const addConnection = bindActionCreators(requestAddConnectionAction, dispatch)
  const updateConnection = bindActionCreators(requestUpdateConnectionAction, dispatch)
  const fetchConnectionsWithDetails = bindActionCreators(fetchConnectionsWithDetailsAction, dispatch)

  const companyId = useSelector((state) => getSelectedCompanyId(state))
  const { useCaseId } = useSelector((state) => getUseCaseItem(state))
  const sources = useSelector((state) => getAvailableSources(state))
  const isFetching = useSelector((state) => isFetchingHermes(state))
  const modalDetails = useSelector((state) => getModalDetails<AuthDataSourceModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const open = modalPageName === AUTH_DATA_SOURCE_MODAL_NAME

  const {
    icon,
    title: sourceName,
    help: sourceHelp,
    sourceId,
    returnTo,
    description: sourceDescription,
    documentationLink: sourceDocumentationLink,
    videoLink: sourceVideoLink,
    connectionId,
    videoHeight,
  } = modalDetails

  const selectedSource = (sources?.find((e) => e.sourceId === sourceId) || {}) as Hermes.SourceDetails
  const isEdit = Boolean(connectionId)
  const isOAuthRequired = checkifOAuthRequired(selectedSource)
  const isAuthDone = isOAuthDone(formValues)

  useEffect(() => {
    if (!isEdit) {
      const configuration = getConfigFromSource(sourceId, sources, getFormattedCurrentDate())

      setFormValues({
        configuration,
        sourceId,
        streams: [],
      } as any)
    } else {
      const fetchConnection = async () => {
        setLoading(true)

        const apiConnection = await API.getConnection(companyId, connectionId)

        setFormValues({
          name: apiConnection.name,
          configuration: apiConnection.configuration,
          sourceId,
          streams: Object.assign({}, ...apiConnection.streams.map((s) => ({ [encodeStreamName(s.name)]: s }))),
        } as Hermes.ConnectionFormItem)

        setLoading(false)
      }

      fetchConnection()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, connectionId])

  const handleClose = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: returnTo,
        modalDetails: {
          ...modalDetails,
          connectionId: '',
          returnTo: DATA_SOURCES_MODAL_NAME,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const handleCloseSuccess = (newConnectionId: string) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: DATA_SOURCE_CONNECTED_MODAL_NAME,
        modalDetails: {
          ...modalDetails,
          connectionId: newConnectionId,
          returnTo: AUTH_DATA_SOURCE_MODAL_NAME,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const updateConnections = async (newConnectionId: string) => {
    const newTransformation = {
      connectionId: newConnectionId,
      connectionVars: [],
    }

    try {
      const { connections } = await API.getTransformation(companyId, useCaseId)

      await API.updateTransformation({ companyId, usecaseId: useCaseId, connections: [...connections, ...[newTransformation]] })
    } catch (e) {
      await API.addTransformation({ companyId, usecaseId: useCaseId, connections: [newTransformation] })
    }

    fetchConnectionsWithDetails({ useCaseId, companyId, updateConnectView: true })
  }

  const handleSubmitSource = async (
    values: Hermes.ConnectionFormItem,
    { setSubmitting } :
    { setSubmitting(state: boolean): void },
  ) => {
    const { configuration } = values

    try {
      setSubmitting(true)

      const apiStreams = await API.getSourceSchema({
        sourceId,
        configuration: values.configuration,
      })

      const payload = {
        sourceId,
        companyId,
        name: values.name,
        configuration,
        streams: apiStreams,
      }

      const newConnectionId = await API.addConnection(payload)

      addConnection({
        connectionId: newConnectionId,
        name: values.name,
        createdAt: new Date().toISOString(),
      })

      updateConnections(newConnectionId)

      showToast({
        message: intl.formatMessage({ id: 'connect.modal.connect.connection_auth_done' }, { name: values.name }),
        severity: TOAST_TYPE_SUCCESS,
      })

      handleCloseSuccess(newConnectionId)
    } catch (e: any) {
      showToast({
        message: e.message,
        severity: TOAST_TYPE_ERROR,
      })
    }

    setSubmitting(false)
  }

  const handleEditSource = async (
    values: Hermes.ConnectionFormItem,
    { setSubmitting } :
    { setSubmitting(state: boolean): void },
  ) => {
    const { configuration } = values
    const streamConfig = Object.entries(values.streams).map(([name, data]) => {
      return data
    })

    const payload = {
      connectionId,
      companyId,
      name: values.name,
      configuration,
      streams: streamConfig,
    } as UpdateConnectionPayload

    setSubmitting(true)

    try {
      const newConnectionId = await API.updateConnection(payload)

      updateConnection({
        connectionId: newConnectionId,
        name: values.name,
      })

      showToast({
        message: intl.formatMessage({ id: 'connect.modal.connect.connection_auth_edit_done' }, { name: values.name }),
        severity: TOAST_TYPE_SUCCESS,
      })

      handleCloseSuccess(newConnectionId)
    } catch (e: any) {
      showToast({ message: e.message, severity: TOAST_TYPE_ERROR })
    }

    setSubmitting(false)
  }

  const {
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    isSubmitting,
    touched,
    errors,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues: formValues,
    onSubmit: isEdit ? handleEditSource : handleSubmitSource,
    enableReinitialize: true,
    validate: (valuesToValidate: Hermes.ConnectionFormItem) => validateAuthDataModal(valuesToValidate, {}, intl, selectedSource),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open}
      title={sourceName}
      handleClose={handleClose}
      SidePanelIcon={icon}
      hasUnsavedChanges={dirty || isSubmitting}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={sourceHelp}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={(isFetching || loading)}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer divider={false}>
                <FormLayoutItem xs={12}>
                  <Typography
                    variant='h3Alt'
                    sx={getTextBackgroundFillStyles('linear-gradient(219.13deg, #8F6BDE 0.5%, #F06DAC 97.79%)')}
                  >
                    {
                      isEdit ? (
                        intl.formatMessage({ id: 'connect.modal.connect.edit_connect_with' }, { name: sourceName })
                      ) : (
                        intl.formatMessage({ id: 'connect.modal.connect.connect_with' }, { name: sourceName })
                      )
                    }
                  </Typography>

                  <Typography
                    variant='body1'
                    color={theme.palette.new.black_b}
                    mt={2}
                    mb={3}
                  >
                    {sourceDescription}
                  </Typography>

                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <VideoBlockComponent
                      documentationLink={sourceDocumentationLink}
                      videoLink={sourceVideoLink}
                      title={sourceName}
                      videoHeight={videoHeight}
                    />
                  </Box>
                </FormLayoutItem>
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='name'
                    label={intl.formatMessage({ id: 'connectors.modal.name' })}
                    floatingHelp={intl.formatMessage({ id: 'connectors.modal.name.help' })}
                    touched={Boolean(touched.name)}
                    errors={errors.name}
                    value={values.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              {/* @ts-ignore */}
              <ConnectorConfigurationContainer
                values={values}
                touched={touched}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                source={selectedSource}
                customerFacing={true}
                edit={isEdit}
                connectionId={connectionId}
                deleteCallback={handleClose}
                setFormValues={setFormValues}
                isOpen={open}
              />
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='authDataSourceModalBackButton'
              onClick={handleClose}
              type='back'
              disabled={isSubmitting}
            />
            <ModalButtonComponent
              name='authDataSourceModalSubmitButton'
              onClick={handleSubmit as any}
              loading={isSubmitting}
              disabled={isSubmitting || loading || (!dirty && (!isAuthDone && isOAuthRequired)) || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default AuthDataSourceModalContainer
