import * as Yup from 'yup'

import { IntlShape } from 'react-intl'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
    name: Yup.string().max(128).required(intl.formatMessage({ id: 'connect.modal.evaluationProfile.name.required' })),
    timeCutMax: Yup.number().min(0),
    timeCutMin: Yup.number().min(0),
  })

  return shape
}

export default validation
