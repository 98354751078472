import React from 'react'
import { useIntl } from 'react-intl'
import { GridColumnGroupingModel } from '@mui/x-data-grid-premium'
import { useSelector } from '@redux/hooks'
import { getLiveMonitoringTable, isFetchingLiveMonitoringTable } from '@redux/modules/monitor/monitor.selectors'
import { requestLiveMonitoringGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { transformMonitorTableColumns } from '@utils/monitor.utils'
import { getInsightsAbcTotals } from '@redux/modules/insights/insights.selectors'
import { FORCED_RIGHT_BORDER_CLASS_NAME } from '@constants/data-grid.constants'

import {
  DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
  DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
} from '@constants/insights.constants'

import InsightsTableComponent from '@components/insights/InsightsTable'

const LiveMonitoringTableContainer = () => {
  const intl = useIntl()
  const liveMonitoringTable = useSelector((state) => getLiveMonitoringTable(state))
  const isFetching = useSelector((state) => isFetchingLiveMonitoringTable(state))
  const abcTotals = useSelector((state) => getInsightsAbcTotals(state))

  const {
    tableId,
    rows,
    rowCount,
    columns,
    gridState,
    targetName,
  } = liveMonitoringTable

  const alwaysVisibleColumns = [
    DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
    DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
    DEFAULT_ABS_DEVIATION_COLUMN,
    DEFAULT_REL_DEVIATION_COLUMN,
  ]

  const columnGroupingModel = [
    {
      groupId: 'deviations',
      headerName: intl.formatMessage({ id: 'insights.table.deviationGroup' }),
      children: [{ field: DEFAULT_ABS_DEVIATION_COLUMN }, { field: DEFAULT_REL_DEVIATION_COLUMN }],
      headerClassName: FORCED_RIGHT_BORDER_CLASS_NAME,
    },
    {
      groupId: 'values',
      headerName: targetName,
      children: [{ field: DEFAULT_LIVE_MONITORING_TARGET_COLUMN }, { field: DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN }],
      headerClassName: FORCED_RIGHT_BORDER_CLASS_NAME,
    },
  ] as GridColumnGroupingModel

  return (
    <InsightsTableComponent
      tableId={tableId}
      rows={rows}
      rowCount={rowCount}
      totalRowCount={abcTotals.total}
      columns={columns}
      gridState={gridState}
      isFetching={isFetching}
      requestGridStateChangeAction={requestLiveMonitoringGridStateChangeAction}
      columnTransformer={transformMonitorTableColumns}
      defaultSortingColumn={DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN}
      alwaysVisibleColumns={alwaysVisibleColumns}
      columnGroupingModel={columnGroupingModel}
    />
  )
}

export default LiveMonitoringTableContainer
