import {
  call, select,
  put, takeEvery, take,
} from 'redux-saga/effects'

import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { ActionPayload, State } from '@redux/modules/types'
import { prepareConnectOverviewAction } from '@redux/modules/use-case/use-case.actions'
import { PREPARE_CONNECT_OVERVIEW_DONE } from '@redux/modules/use-case/use-case.action-types'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { EXPORT_VIEW_DESTINATION } from '@constants/hermes.constants'

import {
  REQUEST_CONNECTIONS,
  REQUEST_DETAILED_CONNECTIONS,
  REQUEST_REMOVE_CONNECTION,
  REQUEST_DELETE_CONNECTION,
  REQUEST_SOURCES,
  REQUEST_TRANSFORMATIONS,
  RECEIVE_DETAILED_CONNECTIONS,
  CREATE_TRANSFORMATION,
  UPDATE_TRANSFORMATION,
  REMOVE_TRANSFORMATION,
  MODIFY_TRANSFORMATION,
  REQUEST_DESTINATIONS,
  REQUEST_PUBLISHER,
  REQUEST_PUBLISHERS_LIST,
  CREATE_PUBLISHER,
  UPDATE_PUBLISHER,
  REMOVE_PUBLISHER,
  RECEIVE_PUBLISHERS_LIST,
  RECEIVE_PUBLISHER,
  REQUEST_ARTIFACTS_EXPORT_DETAILS,
  RECEIVE_ARTIFACTS_EXPORT_DETAILS,
} from '@redux/modules/hermes/hermes.action-types'

import {
  receiveConnectionsByCompanyIdActionDone,
  receiveTransformationsByUseCaseIdActionDone,
  receiveRemovedConnectionsAction,
  receiveSourcesActionDone,
  receiveConnectionsWithDetailsActionDone,
  startFetchingHermesAction,
  stopFetchingHermesAction,
  fetchConnectionsWithDetailsAction,
  receivePublishersListActionDone,
  receivePublisherActionDone,
  receiveDestinationsActionDone,
  fetchPublisherAction,
  fetchPublishersListAction,
  receiveArtifactExportDetails,
  requestArtifactExportDetails,
} from './hermes.actions'

import {
  AddPublisherActionPayload,
  UpdatePublisherActionPayload,
  RemovePublisherActionPayload,
  AddTransformationPayload,
  FetchConnectionsWithDetailsActionPayload,
  FetchTransformationsByUseCaseIdActionPayload,
  GetPublisherPayload,
  GetPublishersListPayload,
  ModifyTransformationActionPayload,
  RequestDeleteConnectionActionPayload,
  RequestRemoveConnectionActionPayload,
  UpdateTransformationPayload,
  RequestArtifactExportDetailsActionPayload,
} from './hermes.types'

import { getCompanyConnectionsBySource, getPublisher, getPublishers } from './hermes.selectors'

import * as API from './hermes.api'

const CREATE_TRANSFORMATION_SUCCESS = 'connect.modal.data_sources.connection.updated'
const UPDATE_TRANSFORMATION_SUCCESS = 'connect.modal.data_sources.connection.updated'
const MODIFY_TRANSFORMATION_SUCCESS = 'connect.modal.data_sources.connection.modified'
const REMOVE_TRANSFORMATION_SUCCESS = 'connect.modal.data_sources.connection.removed'
const REMOVE_CONNECTION_SUCCESS = 'connect.modal.data_sources.source.removed'
const CREATE_PUBLISHER_SUCCESS = 'connect.modal.publisher.created'
const UPDATED_PUBLISHER_SUCCESS = 'connect.modal.publisher.updated'
const REMOVED_PUBLISHER_SUCCESS = 'connect.modal.publisher.removed'

function* fetchAllSourcesGenerator() {
  try {
    yield put(startFetchingHermesAction(REQUEST_SOURCES))

    const sources: Hermes.SourceDetails[] = yield call(API.fetchSources)
    yield put(receiveSourcesActionDone(sources))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, {})
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_SOURCES))
  }
}

function* fetchAllDestinationsGenerator() {
  try {
    yield put(startFetchingHermesAction(REQUEST_DESTINATIONS))

    const destinations: Hermes.DestinationDetails[] = yield call(API.fetchDestinations)

    yield put(receiveDestinationsActionDone(destinations))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, {})

    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))

    receiveDestinationsActionDone([])
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_DESTINATIONS))
  }
}

function* fetchConnectionsByCompanyId({ payload } : ActionPayload<string>) {
  try {
    yield put(startFetchingHermesAction(REQUEST_CONNECTIONS))

    const connections: Hermes.ConnectionListItem[] = yield call(API.fetchConnections, payload)
    yield put(receiveConnectionsByCompanyIdActionDone(connections))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_CONNECTIONS))
  }
}

function* fetchDetailedConnectionsByUseCaseId({ payload } : ActionPayload<FetchConnectionsWithDetailsActionPayload>) {
  try {
    yield put(startFetchingHermesAction(REQUEST_DETAILED_CONNECTIONS))

    const connections: Hermes.ConnectionListItem[] = yield call(API.fetchConnections, payload.companyId)
    const transformations: Hermes.TransformationItem = yield call(API.getTransformation, payload.companyId, payload.useCaseId, true)

    yield put(receiveConnectionsWithDetailsActionDone({
      connections,
      connectionsForUseCase: transformations?.connections || [],
    }))

    if (payload.updateConnectView) {
      yield put(prepareConnectOverviewAction({ useCaseId: payload.useCaseId, recalculation: true }))

      yield take(PREPARE_CONNECT_OVERVIEW_DONE)
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_DETAILED_CONNECTIONS))
  }
}

function* fetchTransformationsByUseCaseIdGenerator({ payload } : ActionPayload<FetchTransformationsByUseCaseIdActionPayload>) {
  try {
    yield put(startFetchingHermesAction(REQUEST_TRANSFORMATIONS))

    const connections: Hermes.TransformationItem = yield call(API.getTransformation, payload.companyId, payload.useCaseId)

    yield put(receiveTransformationsByUseCaseIdActionDone(connections))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_TRANSFORMATIONS))
  }
}

function* requestRemoveConnection({ payload } : ActionPayload<RequestRemoveConnectionActionPayload>) {
  const {
    companyId,
    connectionId,
  } = payload

  try {
    yield put(startFetchingHermesAction(REQUEST_REMOVE_CONNECTION))

    const removedConnections: string[] = yield call(API.removeConnection, companyId, connectionId)

    yield put(receiveRemovedConnectionsAction(removedConnections))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_REMOVE_CONNECTION))
  }
}

function* requestDeleteConnection({ payload } : ActionPayload<RequestDeleteConnectionActionPayload>) {
  const {
    companyId,
    connectionId,
  } = payload

  try {
    yield put(startFetchingHermesAction(REQUEST_DELETE_CONNECTION))

    yield call(API.removeConnection, companyId, connectionId)

    yield put(fetchConnectionsWithDetailsAction(payload))

    yield take(RECEIVE_DETAILED_CONNECTIONS)

    yield put(changeToastAction({
      useIntl: true,
      message: REMOVE_CONNECTION_SUCCESS,
      severity: TOAST_TYPE_SUCCESS,
    }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_DELETE_CONNECTION))
  }
}

function* createTransformationGenerator({ payload } : ActionPayload<AddTransformationPayload>) {
  try {
    yield put(startFetchingHermesAction(CREATE_TRANSFORMATION))

    yield call(API.addTransformation, payload)

    yield put(changeToastAction({ useIntl: true, message: CREATE_TRANSFORMATION_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(CREATE_TRANSFORMATION))
  }
}

function* updateTransformationGenerator({ payload } : ActionPayload<UpdateTransformationPayload>) {
  try {
    yield put(startFetchingHermesAction(UPDATE_TRANSFORMATION))

    yield call(API.updateTransformation, payload)

    yield put(changeToastAction({ useIntl: true, message: UPDATE_TRANSFORMATION_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(UPDATE_TRANSFORMATION))
  }
}

function* modifyTransformationGenerator({ payload } : ActionPayload<ModifyTransformationActionPayload>) {
  yield put(startFetchingHermesAction(MODIFY_TRANSFORMATION))

  const {
    companyId,
    useCaseId,
    connectionId,
    sourceId,
    toRemove,
  } = payload

  const newTransformation = {
    connectionId,
    connectionVars: [],
  }

  const state: State = yield select()
  const connectionsList: Hermes.ConnectionDetails[] = yield call(getCompanyConnectionsBySource, state, sourceId)
  const newConnectedSource = connectionsList.find((connection) => (connection.connectionId === connectionId && connection.sourceId === sourceId))

  try {
    const { connections } : { connections: Hermes.TransformationConnectionItem[] } = yield call(API.getTransformation, companyId, useCaseId)

    try {
      const newConnections = toRemove ?
        connections.filter((connection) => connection.connectionId !== newConnectedSource?.connectionId) :
        connections.concat([newTransformation])

      if ((newConnections.length === 0) && toRemove) {
        yield call(API.removeTransformation, useCaseId)
      } else {
        yield call(API.updateTransformation, { companyId, usecaseId: useCaseId, connections: newConnections })
      }

      yield put(fetchConnectionsWithDetailsAction(payload))

      yield take(RECEIVE_DETAILED_CONNECTIONS)

      yield put(changeToastAction({
        useIntl: true,
        message: toRemove ? REMOVE_TRANSFORMATION_SUCCESS : MODIFY_TRANSFORMATION_SUCCESS,
        severity: TOAST_TYPE_SUCCESS,
        intlParameters: {
          name: newConnectedSource?.name,
        },
      }))
    } catch (e) {
      const message = parseAndReportErrorResponse(e, payload)
      yield put(changeToastAction({
        message,
        severity: TOAST_TYPE_ERROR,
      }))
    } finally {
      yield put(stopFetchingHermesAction(MODIFY_TRANSFORMATION))
    }
  } catch (e) {
    try {
      yield call(API.addTransformation, { companyId, usecaseId: useCaseId, connections: [newTransformation] })

      yield put(fetchConnectionsWithDetailsAction(payload))

      yield take(RECEIVE_DETAILED_CONNECTIONS)

      yield put(changeToastAction({
        useIntl: true,
        message: MODIFY_TRANSFORMATION_SUCCESS,
        severity: TOAST_TYPE_SUCCESS,
        intlParameters: {
          name: newConnectedSource?.name,
        },
      }))
    } catch (error) {
      const message = parseAndReportErrorResponse(error, payload)
      yield put(changeToastAction({
        message,
        severity: TOAST_TYPE_ERROR,
      }))
    }
  }

  yield put(stopFetchingHermesAction(MODIFY_TRANSFORMATION))
}

function* removeTransformationGenerator({ payload } : ActionPayload<string>) {
  try {
    yield put(startFetchingHermesAction(REMOVE_TRANSFORMATION))

    yield call(API.removeTransformation, payload)

    yield put(changeToastAction({ useIntl: true, message: REMOVE_TRANSFORMATION_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REMOVE_TRANSFORMATION))
  }
}

function* requestPublishersListGenerator({ payload } : ActionPayload<GetPublishersListPayload>) {
  try {
    yield put(startFetchingHermesAction(REQUEST_PUBLISHERS_LIST))

    const publishers: Hermes.PublisherListItem[] = yield call(API.fetchPublishers, payload)

    yield put(receivePublishersListActionDone(publishers))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))

    yield put(receiveSourcesActionDone([]))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_PUBLISHERS_LIST))
  }
}

function* requestPublisherGenerator({ payload } : ActionPayload<GetPublisherPayload>) {
  try {
    yield put(startFetchingHermesAction(REQUEST_PUBLISHER))

    const publisher: Hermes.PublisherItem = yield call(API.getPublisher, payload)

    yield put(receivePublisherActionDone(publisher))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))

    yield put(receivePublisherActionDone({}))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_PUBLISHER))
  }
}

function* updatePublisherGenerator({ payload } : ActionPayload<UpdatePublisherActionPayload>) {
  try {
    yield put(startFetchingHermesAction(UPDATE_PUBLISHER))

    const {
      showToast = true,
      prepareArtifactExportDetails = true,
      useCaseId,
      companyId,
      ...apiPayload
    } = payload

    yield call(API.updatePublisher, apiPayload)

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: UPDATED_PUBLISHER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    if (prepareArtifactExportDetails) {
      yield put(setPrimaryModalPageName(null))

      yield put(requestArtifactExportDetails({
        useCaseId,
        companyId,
      }))

      yield take(RECEIVE_ARTIFACTS_EXPORT_DETAILS)
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(UPDATE_PUBLISHER))
  }
}

function* createPublisherGenerator({ payload } : ActionPayload<AddPublisherActionPayload>) {
  try {
    yield put(startFetchingHermesAction(CREATE_PUBLISHER))

    const {
      showToast = true,
      prepareArtifactExportDetails = true,
      ...apiPayload
    } = payload

    yield call(API.addPublisher, apiPayload)

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: CREATE_PUBLISHER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    if (prepareArtifactExportDetails) {
      yield put(setPrimaryModalPageName(null))

      yield put(requestArtifactExportDetails({
        useCaseId: apiPayload.usecaseId,
        companyId: apiPayload.companyId,
      }))

      yield take(RECEIVE_ARTIFACTS_EXPORT_DETAILS)
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(CREATE_PUBLISHER))
  }
}

function* removePublisherGenerator({ payload } : ActionPayload<RemovePublisherActionPayload>) {
  try {
    yield put(startFetchingHermesAction(REMOVE_PUBLISHER))

    const {
      showToast = true,
      prepareArtifactExportDetails = true,
      useCaseId,
      companyId,
      ...apiPayload
    } = payload

    yield call(API.removePublisher, apiPayload)

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: REMOVED_PUBLISHER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    if (prepareArtifactExportDetails) {
      yield put(setPrimaryModalPageName(null))

      yield put(requestArtifactExportDetails({
        useCaseId,
        companyId,
      }))

      yield take(RECEIVE_ARTIFACTS_EXPORT_DETAILS)
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({
      message,
      severity: TOAST_TYPE_ERROR,
    }))
  } finally {
    yield put(stopFetchingHermesAction(REMOVE_PUBLISHER))
  }
}

function* fetchArtifactsExportDetailsGenerator({ payload } : ActionPayload<RequestArtifactExportDetailsActionPayload>) {
  try {
    yield put(startFetchingHermesAction(REQUEST_ARTIFACTS_EXPORT_DETAILS))

    let config = {} as any

    yield put(fetchPublishersListAction({ companyId: payload.companyId, usecaseId: payload.useCaseId }))

    yield take(RECEIVE_PUBLISHERS_LIST)

    const publishersState: State = yield select()
    const publishers: Hermes.PublisherListItem[] = yield call(getPublishers, publishersState)
    const googleSheetPublisher = publishers.find((item) => item.destinationId === EXPORT_VIEW_DESTINATION)

    if (googleSheetPublisher) {
      yield put(fetchPublisherAction({ publisherId: googleSheetPublisher.publisherId }))

      yield take(RECEIVE_PUBLISHER)

      const publisherState: State = yield select()
      const publisher: Hermes.PublisherItem = yield call(getPublisher, publisherState)

      if (publisher) {
        config = {
          publisherId: publisher.publisherId,
          spreadsheetId: publisher.configuration.spreadsheetId,
          credentials: publisher.configuration.credentials,
        } as any
      }
    }

    yield put(receiveArtifactExportDetails(config as Hermes.SpreadsheetExportItem))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingHermesAction(REQUEST_ARTIFACTS_EXPORT_DETAILS))
  }
}

export function* watchFetchArtifactsExportDetails() {
  yield takeEvery(REQUEST_ARTIFACTS_EXPORT_DETAILS, fetchArtifactsExportDetailsGenerator)
}

export function* watchFetchAllSources() {
  yield takeEvery(REQUEST_SOURCES, fetchAllSourcesGenerator)
}

export function* watchFetchTransformationsByUseCaseId() {
  yield takeEvery(REQUEST_TRANSFORMATIONS, fetchTransformationsByUseCaseIdGenerator)
}

export function* watchFetchConnectionsByCompanyId() {
  yield takeEvery(REQUEST_CONNECTIONS, fetchConnectionsByCompanyId)
}

export function* watchFetchDetailedConnectionsByUseCaseId() {
  yield takeEvery(REQUEST_DETAILED_CONNECTIONS, fetchDetailedConnectionsByUseCaseId)
}

export function* watchRemovedConnections() {
  yield takeEvery(REQUEST_REMOVE_CONNECTION, requestRemoveConnection)
}

export function* watchDeleteConnections() {
  yield takeEvery(REQUEST_DELETE_CONNECTION, requestDeleteConnection)
}

export function* watchCreateTransformation() {
  yield takeEvery(CREATE_TRANSFORMATION, createTransformationGenerator)
}

export function* watchUpdateTransformation() {
  yield takeEvery(UPDATE_TRANSFORMATION, updateTransformationGenerator)
}

export function* watchRemoveTransformation() {
  yield takeEvery(REMOVE_TRANSFORMATION, removeTransformationGenerator)
}

export function* watchModifyTransformation() {
  yield takeEvery(MODIFY_TRANSFORMATION, modifyTransformationGenerator)
}

export function* watchRequestDestinations() {
  yield takeEvery(REQUEST_DESTINATIONS, fetchAllDestinationsGenerator)
}

export function* watchRequestPublisher() {
  yield takeEvery(REQUEST_PUBLISHER, requestPublisherGenerator)
}

export function* watchRequestPublishersList() {
  yield takeEvery(REQUEST_PUBLISHERS_LIST, requestPublishersListGenerator)
}

export function* watchCreatePublisher() {
  yield takeEvery(CREATE_PUBLISHER, createPublisherGenerator)
}

export function* watchUpdatePublisher() {
  yield takeEvery(UPDATE_PUBLISHER, updatePublisherGenerator)
}

export function* watchRemovePublisher() {
  yield takeEvery(REMOVE_PUBLISHER, removePublisherGenerator)
}
