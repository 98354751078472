import React from 'react'
import { Position } from '@xyflow/react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { createId } from '@utils/common.utils'
import { Box, useTheme } from '@mui/material'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CONNECT_PARAMETER_CREATION_MODAL_NAME } from '@constants/modals.constants'
import { requestParameterOverviewAction } from '@redux/modules/parameters/parameters.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { SOURCE_TYPES, TARGET_PARAMETER_PER_ROW, TRIPPLE_CONNECTOR_STYLES } from '@constants/flow.constants'
import { ConnectParameterCreationModalDetails } from '@containers/modals/connect-parameter-creation-modal/ConnectParameterCreationModal.container'
import { getTargetsGroupWidth, getTargetsGroupHeigth } from '@utils/flow.utils'
import { PARETOS_GRADIENT } from '@constants/ui.constants'

import ReactFlowAddNewButtonComponent from '@components/connect-view/flow/ReactFlowAddNewButton'
import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import ReactFlowParameterBlockComponent, { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

export interface ReactFlowTargetsNodeComponentProps {
  /**
   * Data for the node
   */
  data?: {
    /**
     * Number of input types. Determines the number of flow handles
     *
     * @default 0
     */
    numOfInputTypes?: number,
    /**
     * Flag to determine if evaluation profiles are available
     */
    isEvaluationProfilesAvailable?: boolean,
  }
}

const ReactFlowTargetsNodeComponent: React.FC<ReactFlowTargetsNodeComponentProps> = ({ data }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const outputParameters = (useCase.outputParameters || [])
  const { numOfInputTypes = 0, isEvaluationProfilesAvailable } = data || {}
  const numOfNodes = outputParameters.length

  const targetChunks = []
  const flowHandles = []
  const isAddButtonAvailable = Boolean(numOfNodes)

  for (let i = 0; i < outputParameters.length; i += TARGET_PARAMETER_PER_ROW) {
    const chunk = outputParameters.slice(i, i + TARGET_PARAMETER_PER_ROW)
    targetChunks.push(chunk)
  }

  for (let i = 0; i < numOfInputTypes; i += 1) {
    flowHandles.push((
      <ReactFlowHandleComponent
        type='target'
        position={Position.Left}
        id={String(i + 1)}
        key={String(i + 1)}
        style={TRIPPLE_CONNECTOR_STYLES[i]}
      />
    ))
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: CONNECT_PARAMETER_CREATION_MODAL_NAME,
        modalDetails: {
          parameterType: PARAMETER_BLOCK_TYPES.TARGET,
          returnTo: '',
        } as ConnectParameterCreationModalDetails,
      }),
    )
  }

  const onParameterClick = (parameterItem: UseCase.OutputParameter) => {
    dispatch(
      requestParameterOverviewAction(parameterItem),
    )
  }

  return (
    <Box
      data-testid={ReactFlowTargetsNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: getTargetsGroupWidth(numOfNodes),
        height: getTargetsGroupHeigth(numOfNodes),
        background: PARETOS_GRADIENT,
        border: `1px solid ${theme.palette.new.business_black_20}`,
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        cursor: 'default',
      }}
    >
      {flowHandles}

      <Box
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.output' })}
          help={intl.formatMessage({ id: 'connect.block.output.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isAddButtonAvailable}
          white={true}
          parentComponentName={ReactFlowTargetsNodeComponent.name}
        />

        {
          numOfNodes ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                '& .outputParametersChunk': {
                  marginRight: theme.spacing(2),
                },
                '& :last-child': {
                  marginRight: 0,
                },
              }}
            >
              {
                targetChunks.map((chunk, index) => {
                  return (
                    <Box
                      className='outputParametersChunk'
                      key={createId(index, 'chunk')}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                      }}
                    >
                      {
                        chunk.map((item: UseCase.OutputParameter, chunkIndex: number) => {
                          const {
                            name,
                            sourceType,
                            unitLabel,
                            modelOutputParameterId,
                          } = item

                          return (
                            <ReactFlowParameterBlockComponent
                              key={createId(index, name, modelOutputParameterId)}
                              name={name}
                              sourceType={sourceType as SOURCE_TYPES}
                              unitLabel={unitLabel}
                              type={PARAMETER_BLOCK_TYPES.TARGET}
                              onClick={() => onParameterClick(item)}
                              dataTestId={`${ReactFlowParameterBlockComponent.name}-${ReactFlowTargetsNodeComponent.name}-${index}`}
                            />
                          )
                        })
                      }
                    </Box>
                  )
                })
              }
            </Box>
          ) : (
            <ReactFlowAddNewButtonComponent
              buttonText={intl.formatMessage({ id: 'connect.block.output.add' })}
              type={PARAMETER_BLOCK_TYPES.TARGET}
              onAddButtonClick={onAddButtonClick}
              parentComponentName={ReactFlowTargetsNodeComponent.name}
            />
          )
        }
      </Box>

      {
        isEvaluationProfilesAvailable ? (
          <ReactFlowHandleComponent
            type='source'
            position={Position.Right}
          />
        ) : (
          null
        )
      }
    </Box>
  )
}

export default ReactFlowTargetsNodeComponent
