import { State } from '@redux/modules/types'

import { USE_CASE_FAMILY_TYPES } from '@constants/use-cases.constants'

import {
  isArticleAllocationFamilyCheck,
  isRecommendationFamilyCheck,
} from '@utils/use-cases.utils'

import {
  REQUEST_USE_CASES,
  REQUEST_USE_CASE,
  CREATE_USE_CASE,
  SET_USE_CASE_CONTACTS,
  UPDATE_USE_CASE,
  DELETE_USE_CASE,
  PREPARE_CONNECT_OVERVIEW,
  CREATE_ARTIFACT_MAPPINGS,
  REQUEST_ARTIFACT_MAPPINGS,
  DELETE_ARTIFACT_MAPPINGS,
  UPDATE_DEMAND_PROBLEM_DEFINITION,
  REQUEST_DEMAND_PROBLEM_DEFINITION,
  MANAGE_USE_CASE,
  CREATE_FORECAST_PARAMETERS,
  REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS,
  REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS,
  SET_USE_CASE_META_DATA_COLUMNS,
} from './use-case.action-types'

export const getUseCaseSpecifiedMetaDataColumns = (state: State) => {
  return state.useCase.specifiedMetaDataColumns
}

export const getUseCaseAvailableMetaDataColumns = (state: State) => {
  return state.useCase.availableMetaDataColumns
}

export const getDemandProblemDefinition = (state: State) => {
  return state.useCase.demandProblemDefintion
}

export const getUseCasesList = (state: State) => {
  return state.useCase.list
}

export const getDemandUseCasesList = (state: State) => {
  return state.useCase.list.filter((useCase) => useCase.family === USE_CASE_FAMILY_TYPES.DEMAND)
}

export const getSortedUseCasesList = (state: State) => {
  const list = [...(getUseCasesList(state) || [])]

  return list.sort((a, b) => {
    const inputA = a.name.toUpperCase()
    const inputB = b.name.toUpperCase()

    if (inputA < inputB) {
      return -1
    }
    if (inputA > inputB) {
      return 1
    }

    return 0
  })
}

export const getFullArtifactMappings = (state: State) => {
  return state.useCase.artifactsMappings
}

export const getUseCaseItem = (state: State) => {
  return state.useCase.item
}

export const isArticleAllocationUseCase = (state: State) => {
  return isArticleAllocationFamilyCheck(state.useCase.item.family)
}

export const isRecommendationUseCase = (state: State) => {
  return isRecommendationFamilyCheck(state.useCase.item.family)
}

export const getUseCaseFreezeStatus = (state: State) => {
  return state.useCase.item.frozen === 'True'
}

export const getConnectOverviewNodes = (state: State) => {
  return state.useCase.connectOverviewNodes
}

export const getConnectOverviewEdges = (state: State) => {
  return state.useCase.connectOverviewEdges
}

export const isFetchingConnectOverviewElements = (state: State) => {
  return state.useCase.fetchingKeys.includes(PREPARE_CONNECT_OVERVIEW)
}

export const isFetchingUseCase = (state: State) => {
  return state.useCase.fetchingKeys.includes(REQUEST_USE_CASE)
}

export const isFetchingUseCaseArtifactMappings = (state: State) => {
  return state.useCase.fetchingKeys.includes(REQUEST_ARTIFACT_MAPPINGS)
}

export const isFetchingUseCases = (state: State) => {
  return state.useCase.fetchingKeys.includes(REQUEST_USE_CASES) ||
         state.useCase.fetchingKeys.includes(REQUEST_USE_CASE)
}

export const isFetchingDemandProblemDefinition = (state: State) => {
  return state.useCase.fetchingKeys.includes(REQUEST_DEMAND_PROBLEM_DEFINITION)
}

export const isFetchingUseCaseMetaDataColumns = (state: State) => {
  return state.useCase.fetchingKeys.includes(REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS) ||
         state.useCase.fetchingKeys.includes(REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS)
}

export const isSubmittingUseCase = (state: State) => {
  return state.useCase.fetchingKeys.includes(CREATE_USE_CASE) ||
         state.useCase.fetchingKeys.includes(UPDATE_USE_CASE) ||
         state.useCase.fetchingKeys.includes(DELETE_USE_CASE) ||
         state.useCase.fetchingKeys.includes(SET_USE_CASE_CONTACTS) ||
         state.useCase.fetchingKeys.includes(CREATE_ARTIFACT_MAPPINGS) ||
         state.useCase.fetchingKeys.includes(MANAGE_USE_CASE) ||
         state.useCase.fetchingKeys.includes(CREATE_FORECAST_PARAMETERS) ||
         state.useCase.fetchingKeys.includes(UPDATE_DEMAND_PROBLEM_DEFINITION) ||
         state.useCase.fetchingKeys.includes(SET_USE_CASE_META_DATA_COLUMNS)
}

export const isDeletingArtifactUseCase = (state: State) => {
  return state.useCase.fetchingKeys.includes(DELETE_ARTIFACT_MAPPINGS)
}
