import {
  put, call, select,
  take, takeLatest,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { DATA_GRIDS, DEFAULT_PAGE_SIZE } from '@constants/data-grid.constants'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium'
import { ANALYZE_TABLE_CONFIG_VERSION, DEFAULT_ANALYZE_PREDICTION_COLUMN, DEFAULT_ANALYZE_TARGET_COLUMN } from '@constants/insights.constants'

import {
  createChartRequestHandler,
  createGridStateChangeHandler,
  createResetViewGenerator,
  createTableRequestHandler,
} from '@utils/insights.utils'

import { getDataGridId } from '@utils/data-grid.utils'
import { createAnalyzeDataGridState } from '@utils/analyze.utils'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import {
  requestInsightsAbcTotalsAction,
  requestInsightsPipelineRunInfoAction,
} from '@redux/modules/insights/insights.actions'

import {
  getSearchTermFromFilterModel,
  convertMUIAggregationModelToAPIAggregationReq,
  convertMUIPageNumberToAPIPageNumber,
  convertMUIPageSizeToAPIPageSize,
  convertMUISortingModelToAPISortReq,
  prepareAPIFilters,
  convertMUIRowSelectionModelToAPIRowFilterReq,
  convertTimeWindowIntoAPIDateRange,
  prepareAPILogicalOperator,
  parseStoredTimeWindow,
} from '@redux/modules/hera/hera.utils'

import * as API from '@redux/modules/hera/hera.api'

import {
  RECEIVE_ANALYZE_TIME_WINDOW_DETAILS,
  REQUEST_ANALYZE_TIME_WINDOW_DETAILS,
  REQUEST_ANALYZE_CHART,
  RECEIVE_ANALYZE_CHART,
  RECEIVE_ANALYZE_TABLE,
  REQUEST_ANALYZE_GRID_STATE_CHANGE,
  REQUEST_ANALYZE_TABLE,
  REQUEST_ANALYZE_VIEW,
  RESET_ANALYZE,
  RECEIVE_ANALYZE_GRID_STATE_CHANGE,
} from './analyze.action-types'

import {
  receiveAnalyzeTimeWindowDetailsAction,
  requestAnalyzeTimeWindowDetailsAction,
  receiveAnalyzeChartAction,
  requestAnalyzeChartAction,
  requestAnalyzeTableAction,
  receiveAnalyzeTableAction,
  receiveAnalyzeGridStateChangeAction,
  startFetchingAnalyzeAction,
  stopFetchingAnalyzeAction,
  requestAnalyzeViewAction,
  requestAnalyzeGridStateChangeAction,
} from './analyze.actions'

import {
  RequesAnalyzeChartActionPayload,
  RequestAnalyzeTableActionPayload,
  RequestAnalyzeGridStateChangePayload,
  RequestAnalyzeActionPayload,
} from './analyze.types'

import {
  getAnalyzeTable,
  getAnalyzeTableState,
  isFetchingAnalyzeTable,
  getAnalyzeTimeWindowDetails,
} from './analyze.selectors'

export const initialGridState = {
  abcFilter: undefined,
  filterModel: {
    items: [],
    logicOperator: 'and',
    quickFilterValues: [''],
  },
  sortModel: [{ field: DEFAULT_ANALYZE_PREDICTION_COLUMN, sort: 'desc' }],
  aggregationModel: {
    [DEFAULT_ANALYZE_PREDICTION_COLUMN]: 'sum',
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: [DEFAULT_ANALYZE_PREDICTION_COLUMN],
  },
  paginationModel: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
  columnVisibilityModel: {},
  internalColumnVisibilityModel: {},
  timeWindow: undefined,
  rowSelectionModel: [],
  rowSelectionModelMode: 'exclude',
} as Analyze.AnalyzeGridState

export function* requestAnalyzeViewGenerator({ payload }: ActionPayload<RequestAnalyzeActionPayload>) {
  try {
    yield put(startFetchingAnalyzeAction(REQUEST_ANALYZE_VIEW))

    yield put(fetchUseCaseAction({
      useCaseId: payload.useCaseId,
      includeDemandProblemDefinition: true,
    }))

    yield take(RECEIVE_USE_CASE)

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const useCaseIdToUse = useCase.demandUseCaseId || payload.useCaseId

    yield put(requestAnalyzeTimeWindowDetailsAction({ useCaseId: useCaseIdToUse }))

    yield put(requestInsightsPipelineRunInfoAction({ useCaseId: useCaseIdToUse }))

    yield put(requestInsightsAbcTotalsAction({ useCaseId: useCaseIdToUse }))

    yield take(RECEIVE_ANALYZE_TIME_WINDOW_DETAILS)

    const stateWithLatestTimeWindowDetails: State = yield select()
    const timeWindowDetails: Analyze.AnalyzeTimeWindowDetailsAPIResponse = yield call(getAnalyzeTimeWindowDetails, stateWithLatestTimeWindowDetails)
    const tableId: string = yield call(getDataGridId, DATA_GRIDS.ANALYZE_TABLE, ANALYZE_TABLE_CONFIG_VERSION, useCaseIdToUse)
    const gridStateFromStorage: Analyze.AnalyzeGridState = yield call(createAnalyzeDataGridState, tableId, initialGridState)

    /**
     * If the time window is not set in the session storage, set it to the default.
     */
    if (!gridStateFromStorage.timeWindow) {
      Object.assign(gridStateFromStorage, {
        timeWindow: parseStoredTimeWindow([timeWindowDetails.dateFrom, timeWindowDetails.dateTo]),
      })
    }

    yield put(requestAnalyzeTableAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequestAnalyzeTableActionPayload))

    yield put(requestAnalyzeChartAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequesAnalyzeChartActionPayload))

    yield take(RECEIVE_ANALYZE_TABLE)

    yield take(RECEIVE_ANALYZE_CHART)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalyzeAction(REQUEST_ANALYZE_VIEW))
  }
}

export const requestAnalyzeGridStateChangeGenerator = createGridStateChangeHandler<
  RequestAnalyzeGridStateChangePayload,
  Analyze.AnalyzeGridState,
  Analyze.AnalyzeTable
>({
  getGridState: getAnalyzeTableState,
  getTableDetails: getAnalyzeTable,
  receiveGridStateChangeAction: receiveAnalyzeGridStateChangeAction,
  requestTableAction: requestAnalyzeTableAction,
  requestChartAction: requestAnalyzeChartAction,
  receiveTableAction: receiveAnalyzeTableAction,
})

export const requestAnalyzeViewChartGenerator = createChartRequestHandler<
  RequesAnalyzeChartActionPayload,
  Analyze.AnalyzeChartAPIRequest,
  Analyze.AnalyzeChartAPIResponse,
  RequestAnalyzeGridStateChangePayload
>({
  includeLastYear: true,
  receiveGridStateChangeType: RECEIVE_ANALYZE_GRID_STATE_CHANGE,
  receiveTableActionType: RECEIVE_ANALYZE_TABLE,
  startFetchingAction: () => startFetchingAnalyzeAction(REQUEST_ANALYZE_CHART),
  stopFetchingAction: () => stopFetchingAnalyzeAction(REQUEST_ANALYZE_CHART),
  getTableFetchingState: isFetchingAnalyzeTable,
  requestChartAPI: API.getAnalyzeChart,
  receiveChartAction: receiveAnalyzeChartAction,
  requestGridStateChangeAction: requestAnalyzeGridStateChangeAction,
  convertAPIPayload: (payload, shouldPreselectRows) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    aggregations: convertMUIAggregationModelToAPIAggregationReq(payload.aggregationModel),
    rowFilters: convertMUIRowSelectionModelToAPIRowFilterReq(payload.rowSelectionModel, payload.rowSelectionModelMode, shouldPreselectRows),
    abcFilter: payload.abcFilter,
  }),
})

export const requestAnalyzeViewTableGenerator = createTableRequestHandler<
  RequestAnalyzeTableActionPayload,
  Analyze.AnalyzeTablePaginatedAPIRequest,
  Analyze.AnalyzeTablePaginatedAPIResponse,
  Analyze.AnalyzeGridState
>({
  tableName: DATA_GRIDS.ANALYZE_TABLE,
  tableVersion: ANALYZE_TABLE_CONFIG_VERSION,

  startFetchingAction: () => startFetchingAnalyzeAction(REQUEST_ANALYZE_TABLE),
  stopFetchingAction: () => stopFetchingAnalyzeAction(REQUEST_ANALYZE_TABLE),
  getInitialGridState: (payload) => {
    return Object.assign(initialGridState, {
      timeWindow: payload.timeWindow,
    })
  },
  createGridState: (tableId, finalState) => createAnalyzeDataGridState(tableId, finalState),

  requestTableAPI: API.getAnalyzeTable,
  receiveTableAction: receiveAnalyzeTableAction,
  retryAction: requestAnalyzeViewAction,
  convertAPIPayload: (payload) => {
    /**
     * Target column has been removed form table - https://paretos.atlassian.net/browse/PD-8863
     * But the aggregation should be applied to the target on the chart.
     * So we need to remove the target column from the aggregation model of table but keep for chart.
     */
    const aggregationsPayload = convertMUIAggregationModelToAPIAggregationReq(payload.aggregationModel)?.filter((item) => {
      return item.column !== DEFAULT_ANALYZE_TARGET_COLUMN
    })

    return {
      useCaseId: payload.useCaseId,
      groupBy: payload.groupingModel,
      pageSize: convertMUIPageSizeToAPIPageSize(payload.paginationModel?.pageSize),
      pageNumber: convertMUIPageNumberToAPIPageNumber(payload.paginationModel?.page),
      sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
      searchTerm: getSearchTermFromFilterModel(payload.filterModel),
      filters: prepareAPIFilters(payload.filterModel),
      dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
      logicalOperator: prepareAPILogicalOperator(payload.filterModel),
      aggregations: aggregationsPayload,
      abcFilter: payload.abcFilter,
    }
  },
})

export const resetAnalyzeViewGenerator = createResetViewGenerator({
  receiveTableAction: receiveAnalyzeTableAction,
  receiveChartAction: receiveAnalyzeChartAction,
})

export function* requestAnalyzeTimeWindowDetailsGenerator({ payload }: ActionPayload<RequestAnalyzeActionPayload>) {
  try {
    yield put(startFetchingAnalyzeAction(REQUEST_ANALYZE_TIME_WINDOW_DETAILS))

    const response: Hera.BaseTimeWindowDetailsAPIResponse = yield call(API.getAnalyzeTimeWindowDetails, {
      useCaseId: payload.useCaseId,
    })

    yield put(receiveAnalyzeTimeWindowDetailsAction(response))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalyzeAction(REQUEST_ANALYZE_TIME_WINDOW_DETAILS))
  }
}

export function* watchRequestAnalyzeViewTable() {
  yield takeLatest(REQUEST_ANALYZE_TABLE, requestAnalyzeViewTableGenerator)
}

export function* watchResetAnalyze() {
  yield takeLatest(RESET_ANALYZE, resetAnalyzeViewGenerator)
}

export function* watchRequestGridStateChange() {
  yield takeLatest(REQUEST_ANALYZE_GRID_STATE_CHANGE, requestAnalyzeGridStateChangeGenerator)
}

export function* watchRequestAnalyzeViewChart() {
  yield takeLatest(REQUEST_ANALYZE_CHART, requestAnalyzeViewChartGenerator)
}

export function* watchRequestAnalyzeView() {
  yield takeLatest(REQUEST_ANALYZE_VIEW, requestAnalyzeViewGenerator)
}

export function* watchRequestAnalyzeTimeWindowDetails() {
  yield takeLatest(REQUEST_ANALYZE_TIME_WINDOW_DETAILS, requestAnalyzeTimeWindowDetailsGenerator)
}

export default {
  watchRequestAnalyzeView,
  watchRequestAnalyzeViewTable,
  watchRequestAnalyzeViewChart,
  watchRequestGridStateChange,
  watchRequestAnalyzeTimeWindowDetails,
  watchResetAnalyze,
}
