import { handleActions } from 'redux-actions'
import { InsightsState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  START_FETCHING_INSIGHTS,
  STOP_FETCHING_INSIGHTS,
  RECEIVE_INSIGHTS_ABC_TOTALS,
  RECEIVE_INSIGHTS_PIPELINE_RUN_INFO,
  RECEIVE_PROMOTIONS_SETTINGS_CHANGE,
  RECEIVE_PROMOTIONS_VISIBILITY_CHANGE,
  RECEIVE_LAST_YEAR_VISIBILITY_CHANGE,
} from './insights.action-types'

import {
  receiveInsightsAbcTotals,
  receiveInsightsPipelineInfo,
  receivePromotionsVisibility,
  receivePromotionsSettings,
  receiveLastYearVisibility,
  initialState,
} from './insights.reducers'

export default handleActions<InsightsState, any>({
  [START_FETCHING_INSIGHTS]: startFetchingAction<InsightsState>,
  [STOP_FETCHING_INSIGHTS]: stopFetchingAction<InsightsState>,
  [RECEIVE_INSIGHTS_ABC_TOTALS]: receiveInsightsAbcTotals,
  [RECEIVE_INSIGHTS_PIPELINE_RUN_INFO]: receiveInsightsPipelineInfo,
  [RECEIVE_PROMOTIONS_VISIBILITY_CHANGE]: receivePromotionsVisibility,
  [RECEIVE_PROMOTIONS_SETTINGS_CHANGE]: receivePromotionsSettings,
  [RECEIVE_LAST_YEAR_VISIBILITY_CHANGE]: receiveLastYearVisibility,
}, initialState)
