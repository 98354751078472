import { IntlShape } from 'react-intl'

import {
  GridAggregationModel,
  GridColDef,
} from '@mui/x-data-grid-premium'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { aggregationModelTransformer, createInsightsDataGridState } from '@utils/insights.utils'
import { transformColumnLabel, getAllowedOperators } from '@redux/modules/hera/hera.utils'
import {
  DEFAULT_ANALYZE_PREDICTION_COLUMN,
  DEFAULT_ANALYZE_TARGET_COLUMN,
  INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
  INSIGHTS_PINNED_PREDICTION_MIN_WIDTH,
} from '@constants/insights.constants'

/**
 * Creates the initial data grid state
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export const createAnalyzeDataGridState = (tableId: string, initialState: Analyze.AnalyzeGridState) => {
  return createInsightsDataGridState<Analyze.AnalyzeGridState>(tableId, initialState)
}

/**
 * Returns the transformed columns with custom header names and filters
 * @param intl React Intl
 * @param columns Columns
 * @returns Columns with custom header names and filters
 */
export const transformAnalyzeTableColumns = (intl: IntlShape, columns?: Insights.SimplifiedGridColumnDefinition[]) => {
  if (!columns) {
    return []
  }

  return columns.map((column) => {
    const colCopy = { ...column } as GridColDef

    if (column.field === DEFAULT_ANALYZE_PREDICTION_COLUMN) {
      Object.assign(colCopy, {
        headerAlign: INSIGHTS_PINNED_COLUMN_DEFAULT_HEADER_ALIGN,
        minWidth: INSIGHTS_PINNED_PREDICTION_MIN_WIDTH,
        headerName: intl.formatMessage({
          id: 'insights.table.predictionColumn',
        }, {
          name: column.headerName,
        }),
      })
    } else {
      Object.assign(colCopy, {
        headerName: transformColumnLabel(column.headerName),
      })
    }

    if (colCopy.type === 'number') {
      colCopy.valueFormatter = (value) => {
        if (value === null) {
          return intl.formatMessage({ id: 'common.na' })
        }

        if (!value) {
          return value
        }

        return defaultNumberFormatter(value, {
          intl,
        })
      }
    }

    return {
      ...colCopy,
      filterOperators: getAllowedOperators(intl, column.type!),
    }
  })
}

/**
 * Aggregation model transformer for analyze table
 * @param currentAggregationModel Current aggregation model
 * @param newAggregationModel New aggregation model
 * @returns Synced aggregation model
 */
export const analyzeAggregationModelTransformer = (prevAggregationModel: GridAggregationModel, newAggregationModel: GridAggregationModel) => {
  return aggregationModelTransformer(prevAggregationModel, newAggregationModel, {
    target: DEFAULT_ANALYZE_TARGET_COLUMN,
    prediction: DEFAULT_ANALYZE_PREDICTION_COLUMN,
  })
}
